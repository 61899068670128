import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import TitleService from "../components/TitleService";
import { TbSocial } from "react-icons/tb";
import { TbClick } from "react-icons/tb";
import { MdOutlineEmail } from "react-icons/md";
import { GoVideo } from "react-icons/go";
import { TbCircleSquare } from "react-icons/tb";
import { SiMicrostrategy } from "react-icons/si";
import { PiStrategy } from "react-icons/pi";
import { RiAdvertisementLine } from "react-icons/ri";
import { TbSeo } from "react-icons/tb";

// gsap
import { ScrollTrigger } from "gsap/ScrollTrigger";

const servArray = [
  {
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/bg-serv1_crs3p3.jpg",
    icon: <TbSocial />,
    title: "Social Media Management",
    content:
      "Connect, Engage, and Grow: Harness the power of social platforms with Aiur's comprehensive social media management. We tailor your presence on Facebook, Instagram, Twitter, LinkedIn, TikTok, YouTube, and more, ensuring your message not only reaches but resonates with your audience. Our strategic content curation and community interaction plans are designed to build your brand's online community and engage with them in meaningful ways.",
  },
  {
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/bg-serv2_yt11y3.jpg",
    icon: <TbClick />,
    title: "Pay-Per-Click Advertising (PPC)",
    content:
      "Precision Targeting, Measurable Results: Propel your business to new heights with Aiur's PPC services. We craft targeted ad campaigns that place your brand in front of the right people at the right time. With our finger on the pulse of PPC trends and data-driven strategies, we maximize your ROI, driving traffic that converts into customers.",
  },
  {
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/bg-serv3_ph7dum.jpg",
    icon: <MdOutlineEmail />,
    title: "Email Marketing Campaigns",
    content:
      "Inboxes Welcoming Your Message: Break through the digital noise with personalized, impactful email campaigns. Aiur's email marketing strategies are crafted to open a direct line of communication with your customers, delivering curated content that encourages clicks and fosters loyalty.",
  },
  {
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/bg-serv4_dvxu20.jpg",
    icon: <GoVideo />,
    title: "Video Production & Editing",
    content:
      "Visual Stories that Captivate: Capture your audience's attention with compelling video content. At Aiur, we specialize in producing and editing high-quality videos that tell your brand's story, showcase your products, and engage viewers. From conceptualization to final cut, our video content stands out in today's visually saturated market.",
  },
  {
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/bg-serv5_cy1b06.jpg",
    icon: <TbCircleSquare />,
    title: "Infographics & Visual Content",
    content:
      "Data Transformed into Visual Narratives: Translate complex data into clear and compelling visuals with Aiur's infographic and visual content services. Our designers create stunning, informative graphics that simplify information and captivate your audience, enhancing understanding and retention.",
  },
  {
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/bg-serv6_m82eqj.jpg",
    icon: <SiMicrostrategy />,
    title: "Content Strategy Development",
    content:
      "Blueprints for Digital Success: Build a solid foundation for your content with Aiur's strategy development services. We help you define goals, identify key audiences, and craft a roadmap for content that engages, converts, and retains. Your content strategy will align with your brand's voice and business objectives, paving the way for success.",
  },
  {
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/bg-serv7_velxrb.jpg",
    icon: <PiStrategy />,
    title: "Social Media Strategy",
    content:
      "Strategic Moves in the Social Sphere: Every post, tweet, and share is a move in the strategic game of social media. Aiur develops comprehensive social media strategies that align with your business goals, drive engagement, and build a strong brand presence across all relevant platforms.",
  },
  {
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/bg-serv8_tx0vhz.jpg",
    icon: <RiAdvertisementLine />,
    title: "Social Media Advertising",
    content:
      "Amplify Your Reach Instantly: Leverage Aiur's expertise in social media advertising to place your brand in the spotlight. Our targeted advertising solutions on social platforms ensure that your message is seen by the audiences that matter most, driving awareness and conversions.",
  },
  {
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/bg-serv9_hjkngx.jpg",
    icon: <TbSeo />,
    title: "SEO Development",
    content:
      "Optimizing for Visibility: Elevate your website's position in search engine rankings with Aiur's SEO development services. We optimize your web content, structure, and on-page elements to improve visibility, drive organic traffic, and increase the credibility of your site.",
  },
];

const Services = () =>
  // {
  //   handleTurnOffAudio,
  //   handleTurnOnAudio,
  // }
  {
    const [switchServ, setSwitchServ] = useState(true);
    const [switchServBg, setSwitchServBg] = useState(null);
    const [switchServIcon, setSwitchServIcon] = useState(null);
    const [switchServTitle, setSwitchServTitle] = useState(null);
    const [switchServContent, setSwitchServContent] = useState(null);

    const [canvasBg, setCanvasBg] = useState(null);
    const [canvasIcon, setCanvasIcon] = useState(null);
    const [canvasTitle, setCanvasTitle] = useState(null);
    const [canvasContent, setCanvasContent] = useState(null);
    //
    const Service = ({ icon, title, bg, content }) => {
      return (
        <button
          className="serviceItem"
          style={{
            background: `linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${bg}) center center / cover`,
          }}
          onClick={() => {
            setSwitchServBg(bg);
            setSwitchServIcon(icon);
            setSwitchServTitle(title);
            setSwitchServContent(content);
            setSwitchServ(false);
          }}
        >
          <TitleService icon={icon} title={title} />
        </button>
      );
    };
    useLayoutEffect(() => {
      window.scrollTo({ top: 0, behavior: "auto" });
      ScrollTrigger.refresh();
      return () => {
        ScrollTrigger.getAll().forEach((t) => {
          t.scroll(0);
          t.kill(true);
        });
      };
    }, []);
    return (
      <Container>
        <div className="discover">
          <div className="partLeft">
            <h3>
              Discover the Digital Frontier: Innovate, Integrate, and Inspire
            </h3>
            <p>
              Venture into the vastness of the digital cosmos with Aiur Company,
              where cutting-edge technology meets creativity. Our mission is to
              propel your business into new orbits of success with innovative
              web solutions, dynamic marketing strategies, and a customer
              experience that's out of this world.
            </p>
          </div>
          <div className="partRight">
            <div
              className="ratio ratio-16x9"
              style={{
                border: "2px solid #7ddbf9",
                borderRadius: "1rem",
                overflow: "hidden",
              }}
            >
              <iframe
                src="https://www.youtube.com/embed/_nmLowmjdJ0?rel=0"
                title="YouTube video"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <MobileService>
          <div
            className="serviceItem"
            style={{
              background: `linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${servArray[0].bg}) center center / cover`,
            }}
          >
            <TitleService icon={servArray[0].icon} title={servArray[0].title} />
            <button
              onClick={() => {
                setCanvasBg(servArray[0].bg);
                setCanvasIcon(servArray[0].icon);
                setCanvasTitle(servArray[0].title);
                setCanvasContent(servArray[0].content);
              }}
              className="btn btn-outline-warning"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasPageServices"
              aria-controls="offcanvasPageServices"
            >
              More
            </button>
          </div>
          <div
            className="serviceItem"
            style={{
              background: `linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${servArray[1].bg}) center center / cover`,
            }}
          >
            <TitleService icon={servArray[1].icon} title={servArray[1].title} />
            <button
              onClick={() => {
                setCanvasBg(servArray[1].bg);
                setCanvasIcon(servArray[1].icon);
                setCanvasTitle(servArray[1].title);
                setCanvasContent(servArray[1].content);
              }}
              className="btn btn-outline-warning"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasPageServices"
              aria-controls="offcanvasPageServices"
            >
              More
            </button>
          </div>
          <div
            className="serviceItem"
            style={{
              background: `linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${servArray[2].bg}) center center / cover`,
            }}
          >
            <TitleService icon={servArray[2].icon} title={servArray[2].title} />
            <button
              onClick={() => {
                setCanvasBg(servArray[2].bg);
                setCanvasIcon(servArray[2].icon);
                setCanvasTitle(servArray[2].title);
                setCanvasContent(servArray[2].content);
              }}
              className="btn btn-outline-warning"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasPageServices"
              aria-controls="offcanvasPageServices"
            >
              More
            </button>
          </div>
          <div
            className="serviceItem"
            style={{
              background: `linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${servArray[3].bg}) center center / cover`,
            }}
          >
            <TitleService icon={servArray[3].icon} title={servArray[3].title} />
            <button
              onClick={() => {
                setCanvasBg(servArray[3].bg);
                setCanvasIcon(servArray[3].icon);
                setCanvasTitle(servArray[3].title);
                setCanvasContent(servArray[3].content);
              }}
              className="btn btn-outline-warning"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasPageServices"
              aria-controls="offcanvasPageServices"
            >
              More
            </button>
          </div>
          <div
            className="serviceItem"
            style={{
              background: `linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${servArray[4].bg}) center center / cover`,
            }}
          >
            <TitleService icon={servArray[4].icon} title={servArray[4].title} />
            <button
              onClick={() => {
                setCanvasBg(servArray[4].bg);
                setCanvasIcon(servArray[4].icon);
                setCanvasTitle(servArray[4].title);
                setCanvasContent(servArray[4].content);
              }}
              className="btn btn-outline-warning"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasPageServices"
              aria-controls="offcanvasPageServices"
            >
              More
            </button>
          </div>
          <div
            className="serviceItem"
            style={{
              background: `linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${servArray[5].bg}) center center / cover`,
            }}
          >
            <TitleService icon={servArray[5].icon} title={servArray[5].title} />
            <button
              onClick={() => {
                setCanvasBg(servArray[5].bg);
                setCanvasIcon(servArray[5].icon);
                setCanvasTitle(servArray[5].title);
                setCanvasContent(servArray[5].content);
              }}
              className="btn btn-outline-warning"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasPageServices"
              aria-controls="offcanvasPageServices"
            >
              More
            </button>
          </div>
          <div
            className="serviceItem"
            style={{
              background: `linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${servArray[6].bg}) center center / cover`,
            }}
          >
            <TitleService icon={servArray[6].icon} title={servArray[6].title} />
            <button
              onClick={() => {
                setCanvasBg(servArray[6].bg);
                setCanvasIcon(servArray[6].icon);
                setCanvasTitle(servArray[6].title);
                setCanvasContent(servArray[6].content);
              }}
              className="btn btn-outline-warning"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasPageServices"
              aria-controls="offcanvasPageServices"
            >
              More
            </button>
          </div>
          <div
            className="serviceItem"
            style={{
              background: `linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${servArray[7].bg}) center center / cover`,
            }}
          >
            <TitleService icon={servArray[7].icon} title={servArray[7].title} />
            <button
              onClick={() => {
                setCanvasBg(servArray[7].bg);
                setCanvasIcon(servArray[7].icon);
                setCanvasTitle(servArray[7].title);
                setCanvasContent(servArray[7].content);
              }}
              className="btn btn-outline-warning"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasPageServices"
              aria-controls="offcanvasPageServices"
            >
              More
            </button>
          </div>
          <div
            className="serviceItem"
            style={{
              background: `linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), url(${servArray[8].bg}) center center / cover`,
            }}
          >
            <TitleService icon={servArray[8].icon} title={servArray[8].title} />
            <button
              onClick={() => {
                setCanvasBg(servArray[8].bg);
                setCanvasIcon(servArray[8].icon);
                setCanvasTitle(servArray[8].title);
                setCanvasContent(servArray[8].content);
              }}
              className="btn btn-outline-warning"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasPageServices"
              aria-controls="offcanvasPageServices"
            >
              More
            </button>
          </div>
          <div
            className="offcanvas offcanvas-bottom"
            tabIndex="-1"
            id="offcanvasPageServices"
            style={{
              background: "transparent",
            }}
          >
            <button
              type="button"
              className="btn-close position-absolute"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              style={{
                right: "1rem",
                top: "1rem",
              }}
            ></button>
            <ServExpand
              className="offcanvas-body"
              style={{
                padding: "2rem 1rem",
                gap: "2rem",
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), url(${canvasBg}) center center / cover`,
              }}
            >
              <TitleService icon={canvasIcon} title={canvasTitle} />
              <p>{canvasContent}</p>
            </ServExpand>
          </div>
        </MobileService>
        <DesktopService>
          {switchServ ? (
            <div className="servWrapper">
              <Service
                bg={servArray[0].bg}
                icon={servArray[0].icon}
                title={servArray[0].title}
                content={servArray[0].content}
              />
              <Service
                bg={servArray[1].bg}
                icon={servArray[1].icon}
                title={servArray[1].title}
                content={servArray[1].content}
              />
              <Service
                bg={servArray[2].bg}
                icon={servArray[2].icon}
                title={servArray[2].title}
                content={servArray[2].content}
              />
              <Service
                bg={servArray[3].bg}
                icon={servArray[3].icon}
                title={servArray[3].title}
                content={servArray[3].content}
              />
              <Service
                bg={servArray[4].bg}
                icon={servArray[4].icon}
                title={servArray[4].title}
                content={servArray[4].content}
              />
              <Service
                bg={servArray[5].bg}
                icon={servArray[5].icon}
                title={servArray[5].title}
                content={servArray[5].content}
              />
              <Service
                bg={servArray[6].bg}
                icon={servArray[6].icon}
                title={servArray[6].title}
                content={servArray[6].content}
              />
              <Service
                bg={servArray[7].bg}
                icon={servArray[7].icon}
                title={servArray[7].title}
                content={servArray[7].content}
              />
              <Service
                bg={servArray[8].bg}
                icon={servArray[8].icon}
                title={servArray[8].title}
                content={servArray[8].content}
              />
            </div>
          ) : (
            <ServExpand
              style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), url(${switchServBg}) center center / cover`,
              }}
            >
              <TitleService icon={switchServIcon} title={switchServTitle} />
              <p>{switchServContent}</p>
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => {
                  setSwitchServ(true);
                }}
              >
                Back
              </button>
            </ServExpand>
          )}
        </DesktopService>
      </Container>
    );
  };

export default Services;

const Container = styled.section`
  background: #161751;
  padding: 4rem 0 2rem;
  @media screen and (min-width: 1024px) {
    padding: 3rem 0;
  }
  .discover {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 1rem 0 3rem;
    margin: 0 1rem;
    @media screen and (min-width: 1024px) {
      padding: 2rem 0 3rem;
      margin: 0 2rem;
      flex-direction: row;
    }
    .partLeft {
      width: 100%;
      @media screen and (min-width: 1024px) {
        width: 31rem;
      }
      h3 {
        color: #fbbc04;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      p {
        color: #7ddbf9;
        margin: 0;
        text-align: justify;
      }
    }
    .partRight {
      width: 100%;
      @media screen and (min-width: 768px) {
        width: 25rem;
      }
      @media screen and (min-width: 1024px) {
        width: 31rem;
      }
    }
  }
`;
const MobileService = styled.div`
  display: grid;
  gap: 1rem;
  justify-content: center;
  grid-template-columns: auto;
  @media screen and (min-width: 768px) {
    grid-template-columns: auto auto;
  }
  @media screen and (min-width: 1024px) {
    display: none;
  }
  > div.serviceItem {
    width: 16rem;
    padding: 1rem 0;
  }
`;
const DesktopService = styled.div`
  margin: 0 auto;
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
    width: 46rem;
    height: 26rem;
  }
  .servWrapper {
    height: 100%;
    width: 100%;
    display: grid;
    gap: 0.1rem;
    @media screen and (min-width: 1024px) {
      grid-template-columns: auto auto auto;
    }
  }
`;
const ServExpand = styled.div`
  width: 100%;
  height: 100%;
  border: 5px solid #007dbc;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 1rem;
  > p {
    color: #7ddbf9;
    margin: 0;
  }
`;
