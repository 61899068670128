import React from "react";
import styled from "styled-components";

const Title = ({ h2span, h2, color, letterSpc = "1px" }) => {
  return (
    <Container
      style={{
        color: color,
      }}
    >
      <h1>AIUR</h1>
      <h5
        style={{
          letterSpacing: letterSpc,
        }}
      >
        <span>{h2span}</span>
        <br />
        {h2}
      </h5>
    </Container>
  );
};

export default Title;

const Container = styled.div`
  display: flex;
  gap: 0.2rem;
  font-family: "Turret Road";
  align-items: center;
  padding-bottom: 0.2rem;
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
    line-height: 1;
  }
  h5 {
    font-size: 1.1rem;
    font-weight: bold;
    /* letter-spacing: 1px; */
    margin: 0;
    white-space: nowrap;
    line-height: 1;
    h6 {
      margin: 0;
      font-family: Roboto;
    }
  }
`;
