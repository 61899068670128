import React from "react";
import styled from "styled-components";

const MileStones = ({
  line1 = "",
  line2 = "",
  line3 = "",
  line4 = "",
  mg = "",
}) => {
  return (
    <Container
      style={{
        margin: mg,
      }}
    >
      <h1>
        <span>{line1}</span>
        {line2}
      </h1>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="3"
        height="42"
        viewBox="0 0 3 42"
        fill="none"
      >
        <rect width="1" height="42" transform="translate(1)" fill="#D7B577" />
        <rect width="3" height="3" fill="#EE810B" />
        <rect y="39" width="3" height="3" fill="#EE810B" />
      </svg>
      <h6>
        <span>{line3}</span>
        <br />
        {line4}
      </h6>
    </Container>
  );
};

export default MileStones;

const Container = styled.div`
  margin: 3rem 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  color: #d7b577;
  font-family: Inter;
  @media all and (min-width: 1024px) {
    margin: 3rem 0 0.5rem 2rem;
    justify-content: flex-start;
  }
  h1 {
    font-size: 2.3rem;
    line-height: 1;
    font-weight: bold;
    margin: 0;
    span {
      font-weight: normal;
    }
  }
  svg {
    height: 2rem;
  }
  h6 {
    font-size: 1rem;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 1px;
    white-space: nowrap;
    margin: 0;
    span {
      font-weight: normal;
    }
  }
`;
