import React, { useLayoutEffect } from "react";
import ContactInfor from "../components/ContactInfor";
import styled from "styled-components";

// gsap
import { ScrollTrigger } from "gsap/ScrollTrigger";
const ContactUs = () => {
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    ScrollTrigger.refresh();
    return () => {
      ScrollTrigger.getAll().forEach((t) => {
        t.scroll(0);
        t.kill(true);
      });
    };
  }, []);
  return (
    <Container>
      <div className="frameOutContact">
        <div className="contentLeft">
          <ContactInfor
            position="relative"
            bg="#161751"
            color1="#7DDBF9"
            color2="#7DDBF9"
          />
        </div>
        <div className="frameInContact rounded-4">
          <h3 className="fw-bold">Get in touch today</h3>
          <p>
            Embark on a journey to transform your digital presence with Aiur.
            Whether you're looking to ignite your marketing efforts, streamline
            your business processes, or redefine the user experience, our
            experts are ready to guide you through the cosmos of possibilities.
            Drop us a message or simply fill out the form below to start a
            conversation that could take your business to new frontiers. We're
            here to connect, create, and conquer challenges together.
          </p>
          <form>
            <div className="grInfor">
              <div className="grInfor-item">
                <label htmlFor="formControlInputName" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control rounded-4"
                  id="formControlInputName"
                  placeholder="John Carter"
                />
              </div>
              <div className="grInfor-item">
                <label htmlFor="formControlInputEmail" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control rounded-4"
                  id="formControlInputEmail"
                  placeholder="example@email.com"
                />
              </div>
              <div className="grInfor-item">
                <label htmlFor="formControlInputPhone" className="form-label">
                  Phone
                </label>
                <input
                  type="tel"
                  className="form-control rounded-4"
                  id="formControlInputPhone"
                  placeholder="(123) 456 - 789"
                />
              </div>
              <div className="grInfor-item">
                <label htmlFor="formControlInputCompany" className="form-label">
                  Company
                </label>
                <input
                  type="text"
                  className="form-control rounded-4"
                  id="formControlInputCompany"
                  placeholder="Facebook"
                />
              </div>
            </div>
            <div className="mt-3 mb-4">
              <label htmlFor="FormControlMessage" className="form-label">
                Leave us a message
              </label>
              <textarea
                className="form-control rounded-3"
                id="FormControlMessage"
                rows="3"
                placeholder="Please type your message here..."
              ></textarea>
            </div>
            <button
              type="submit"
              className="btn btn-info rounded-5 d-block mx-auto"
            >
              Send message
            </button>
          </form>
        </div>
      </div>
      <div className="frameOutFaq">
        <h6 className="fw-bold">FREQUENTLY ASKED QUESTIONS</h6>
        <h4 className="fw-bold mb-3">
          Navigating the Stars of Inquiry: Your Questions Answered
        </h4>
        <div className="accordion mx-auto" id="accordionPanelsStayOpenExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse1"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse1"
              >
                What marketing services does Aiur offer?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse1"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                Aiur Marketing specializes in a range of services from galactic
                brand strategy to interstellar social media management. We
                provide SEO optimization, content creation, PPC campaigns, and
                immersive UI/UX design, all crafted with the wisdom of the Aiur
                legacy and the innovation of tomorrow.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse2"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse2"
              >
                Can Aiur help with improving our search engine rankings?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse2"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                Absolutely. Our team of Sentry SEO specialists employs the
                latest algorithms and celestial insights to elevate your
                visibility across the digital cosmos, ensuring that your brand
                shines brightest among the stars of search engine results.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse3"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse3"
              >
                What is Aiur's Pricing template?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse3"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                Our pricing structure is as flexible as the space-time
                continuum, tailored to fit the unique needs of each client.
                Whether you're a fledgling startup or a mighty fleet commander,
                we have a range of packages that adapt to your budget and scale
                with your success.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse4"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse4"
              >
                Does Aiur provide analytics and reporting for marketing
                campaigns?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse4"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                Yes, with Aiur Marketing, you have access to real-time analytics
                and comprehensive reporting that allows you to track the voyage
                of your campaigns across the digital expanse. Our reports are
                detailed star maps that guide strategic decision-making and
                future explorations.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapse5"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapse5"
              >
                Can Aiur assist with content creation for our brand?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapse5"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                Our content creators, known as Probes, are masters of crafting
                messages that resonate across the galaxy. From holographic video
                production to AI-generated blog posts, we create content that
                engages and converts, echoing your brand's voice throughout the
                universe.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactUs;

const Container = styled.section`
  background: #fff;
  .frameOutContact {
    width: 100%;
    position: relative;
    padding: 5rem 1rem 2rem;
    margin-top: 0;
    @media screen and (min-width: 1024px) {
      margin-top: 3rem;
      padding: 2rem;
    }
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      width: 60%;
      height: 100%;
      background: url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/contact-welcome-bg_nzfpc6.png)
        center / cover;
    }
    .contentLeft {
      display: inline-block;
    }
    .frameInContact {
      position: relative;
      background: #161751;
      max-width: 100%;
      padding: 2rem 1rem 2rem;
      margin-top: 3rem;
      @media screen and (min-width: 1024px) {
        max-width: 40rem;
      }
      > h3 {
        text-align: center;
        color: #7ddbf9;
        font-family: "Turret Road";
        letter-spacing: 1px;
      }
      > p {
        color: #7ddbf9;
        font-family: "Turret Road";
        text-align: justify;
      }
      > form {
        > .grInfor {
          display: grid;
          grid-template-columns: auto;
          gap: 1rem;
          @media screen and (min-width: 768px) {
            grid-template-columns: auto auto;
          }
        }
        label {
          color: #007dbc;
          font-feature-settings: "clig" off, "liga" off;
          font-family: "DM Sans";
          font-weight: bold;
        }
        .form-control,
        .form-control::placeholder {
          color: #007dbc;
          font-feature-settings: "clig" off, "liga" off;
          font-family: "DM Sans";
        }
        > button {
          color: #fff;
          font-feature-settings: "clig" off, "liga" off;
          font-family: "DM Sans";
        }
      }
    }
  }
  .frameOutFaq {
    background: #000;
    padding: 3rem 0;
    text-align: center;
    > h6 {
      color: #7ddbf9;
      letter-spacing: 1px;
    }
    > h4 {
      color: #007dbc;
    }
    > .accordion {
      max-width: 100%;
      @media screen and (min-width: 768px) {
        max-width: 35rem;
      }
      @media screen and (min-width: 1024px) {
        max-width: 40rem;
      }
      .accordion-item {
        .accordion-header {
          .accordion-button {
            color: #007dbc;
            font-weight: bold;
          }
        }
        .accordion-collapse {
          text-align: left;
        }
      }
    }
  }
`;
