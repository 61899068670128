import React from "react";
import styled from "styled-components";

const OverlayBtn = ({ text }) => {
  return (
    <Container>
      <span>{text}</span>
      <div className="top-corners"></div>
      <div className="bottom-corners"></div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background: rgba(0, 125, 188, 1);
  color: #7ddbf9;
  font-family: "Turret Road";
  border: none;
  box-shadow: 8px 6px 10px rgba(0, 0, 0, 0.7);
  font-size: 1rem;
  padding: 0.3em 1em;
  @media all and (min-width: 1024px) {
    background: rgba(0, 125, 188, 0.5);
  }
  &:hover {
    background: rgba(0, 125, 188, 1);
  }
  .top-corners {
    position: absolute;
    width: 100%;
    top: 0.1em;
    left: 0;
    &::before {
      position: absolute;
      left: 0.1em;
      content: "";
      width: 0.8em;
      height: 0.8em;
      border-width: 2px 0 0 2px;
      border-style: solid;
      border-color: #7ddbf9;
    }
    &::after {
      position: absolute;
      right: 0.1em;
      content: "";
      width: 0.8em;
      height: 0.8em;
      border-width: 2px 2px 0 0;
      border-style: solid;
      border-color: #7ddbf9;
    }
  }
  .bottom-corners {
    position: absolute;
    width: 100%;
    bottom: 0.9em;
    left: 0;
    &::before {
      position: absolute;
      left: 0.1em;
      content: "";
      width: 0.8em;
      height: 0.8em;
      border-width: 0 0 2px 2px;
      border-style: solid;
      border-color: #7ddbf9;
    }
    &::after {
      position: absolute;
      right: 0.1em;
      content: "";
      width: 0.8em;
      height: 0.8em;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: #7ddbf9;
    }
  }
`;

export default OverlayBtn;
