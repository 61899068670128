import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import Title from "../components/Title";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-creative";
import { Navigation, EffectCreative } from "swiper/modules";

// gsap
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
const VIDEOS = [
  {
    url: "https://www.youtube.com/embed/_nmLowmjdJ0?rel=0",
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/Ack-Build-The-Future_zfli5y.png",
  },
  {
    url: "https://www.youtube.com/embed/2Kk2TgIdnHo?rel=0",
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/Ack-Space-Venture_b6uunc.png",
  },
  {
    url: "https://www.youtube.com/embed/gJW8DtaG6rs?rel=0",
    bg: "https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/Ack-The-Vision-of-Space_rwnx3s.png",
  },
];
const Acknowledgements = () =>
  // {
  //   handleTurnOffAudio,
  //   handleTurnOnAudio,
  // }
  {
    const [videoUrl, setVideoUrl] = useState(VIDEOS[1].url);
    const [videoBg, setVideoBg] = useState(VIDEOS[1].bg);
    const animate = () => {
      gsap.fromTo(
        "#acknowMain .circleAni",
        { autoAlpha: 1, width: 0, height: 0 },
        { autoAlpha: 0, width: "200%", height: "200%", duration: 1 }
      );
    };
    const navigation = {
      prevEl: ".swiper-button-prev",
      nextEl: ".swiper-button-next",
    };
    useLayoutEffect(() => {
      animate();
      window.scrollTo({ top: 0, behavior: "auto" });
      ScrollTrigger.refresh();
      return () => {
        ScrollTrigger.getAll().forEach((t) => {
          t.scroll(0);
          t.kill(true);
        });
      };
    }, []);
    return (
      <Container id="acknowMain">
        <div className="frameVideo">
          <div
            className="frameBg"
            style={{
              background: `url(${videoBg}) center / cover`,
            }}
          ></div>
          <div className="circleAni"></div>
          <div className="frameTitle">
            <h5>THE</h5>
            <h5> &nbsp; VIDEOS</h5>
            <h5> &nbsp; GALLERY</h5>
          </div>
          <div className="contentVid">
            <div className="titleVid">
              <p>
                Journey Through The Decade: <br />
                Travel to the cosmos of Aiur
              </p>
            </div>
            <div className="contentVid-sm">
              <div className="ratio ratio-16x9">
                <iframe
                  src="https://www.youtube.com/embed/_nmLowmjdJ0?rel=0"
                  title="YouTube video"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="ratio ratio-16x9">
                <iframe
                  src="https://www.youtube.com/embed/2Kk2TgIdnHo?rel=0"
                  title="YouTube video"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="ratio ratio-16x9">
                <iframe
                  src="https://www.youtube.com/embed/gJW8DtaG6rs?rel=0"
                  title="YouTube video"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="contentVid-lg ratio ratio-16x9">
              <iframe
                src={`${videoUrl}`}
                title="YouTube video"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="listVidBtn">
            <button
              onClick={() => {
                setVideoUrl(VIDEOS[0].url);
                setVideoBg(VIDEOS[0].bg);
                animate();
              }}
            >
              <img src={VIDEOS[0].bg} alt="" />
            </button>
            <button
              onClick={() => {
                setVideoUrl(VIDEOS[1].url);
                setVideoBg(VIDEOS[1].bg);
                animate();
              }}
            >
              <img src={VIDEOS[1].bg} alt="" />
            </button>
            <button
              onClick={() => {
                setVideoUrl(VIDEOS[2].url);
                setVideoBg(VIDEOS[2].bg);
                animate();
              }}
            >
              <img src={VIDEOS[2].bg} alt="" />
            </button>
          </div>
        </div>
        <div
          style={{
            margin: "3rem 0 0.5rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Title
            h2span="COLLABORATION"
            h2="WORKING PROCEDURE"
            color="#7DDBF9"
            letterSpc="normal"
          />
        </div>
        <Procedure>
          <Swiper
            navigation={navigation}
            effect={"creative"}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            modules={[EffectCreative, Navigation]}
            className="mySwiperProcedure"
          >
            <SwiperSlide>
              <button>
                <img
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/acknow-procedure1_inxjnf.png"
                  alt=""
                  style={{
                    borderRight: "0.3rem solid rgba(32, 160, 218, 0.7)",
                  }}
                />
              </button>
            </SwiperSlide>
            <SwiperSlide>
              <button>
                <img
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/acknow-procedure2_njrdlv.png"
                  alt=""
                  style={{
                    borderRight: "0.3rem solid rgba(32, 160, 218, 0.7)",
                    borderLeft: "0.3rem solid rgba(32, 160, 218, 0.7)",
                  }}
                />
              </button>
            </SwiperSlide>
            <SwiperSlide>
              <button>
                <img
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/acknow-procedure3_yyzaha.png"
                  alt=""
                  style={{
                    borderLeft: "0.3rem solid rgba(32, 160, 218, 0.7)",
                  }}
                />
              </button>
            </SwiperSlide>
          </Swiper>
          <button
            className="swiper-button-prev"
            style={{
              transform: "translateX(-175%)",
            }}
          ></button>
          <button
            className="swiper-button-next"
            style={{
              transform: "translateX(175%)",
            }}
          ></button>
        </Procedure>
      </Container>
    );
  };

export default Acknowledgements;
const Container = styled.section`
  background: #00253a;
  padding-bottom: 2rem;
  .frameVideo {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 5rem 0rem 2rem;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/Ack-The-Vision-of-Space_rwnx3s.png)
        center / cover;
      @media screen and (min-width: 1024px) {
        display: none;
      }
    }
    @media screen and (min-width: 1024px) {
      padding: 2rem;
      margin-top: 3rem;
    }
    .frameBg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      filter: blur(5px);
      display: none;
      @media screen and (min-width: 1024px) {
        display: block;
      }
    }
    .frameTitle {
      position: relative;
      display: flex;
      flex-direction: row;
      @media screen and (min-width: 1024px) {
        position: absolute;
        top: 1rem;
        right: 2rem;
        flex-direction: column;
        align-items: flex-end;
      }
      > h5 {
        color: #fff;
        font-family: Inter;
        font-weight: normal;
        margin: 0;
        &:nth-of-type(2) {
          font-weight: bold;
        }
      }
    }
    .circleAni {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      content: "";
      width: 0;
      height: 0;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 100%;
      opacity: 0;
      visibility: hidden;
    }
    .contentVid {
      position: relative;
      width: 100%;
      @media screen and (min-width: 1024px) {
        width: 35rem;
      }
      .titleVid {
        position: relative;
        color: #fff;
        font-family: "Turret Road";
        letter-spacing: 1px;
        font-weight: bold;
        z-index: 1;
        margin-left: 1rem;
        @media screen and (min-width: 1024px) {
          margin-left: 2rem;
          position: absolute;
          top: 0.5rem;
          left: 0.8rem;
        }
        span {
          font-weight: normal;
        }
      }
      div.ratio {
        border: 0.5rem solid rgba(0, 0, 0, 0.8);
      }
      .contentVid-sm {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @media screen and (min-width: 1024px) {
          display: none;
        }
      }
      .contentVid-lg {
        display: none;
        @media screen and (min-width: 1024px) {
          display: block;
        }
      }
    }
    .listVidBtn {
      position: relative;
      display: none;
      gap: 1rem;
      @media screen and (min-width: 1024px) {
        display: flex;
      }
      > button {
        width: 6rem;
        height: 4rem;
        border-radius: 1rem;
        box-shadow: 0px 0px 10px 0px aliceblue;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
`;
const Procedure = styled.div`
  position: relative;
  margin: 0 auto;
  width: 15rem;
  height: 21rem;
  @media screen and (min-width: 1024px) {
    width: 20rem;
    height: 28rem;
  }
  .mySwiperProcedure {
    width: 100%;
    height: 100%;
    .swiper-slide {
      user-select: none;
      box-shadow: -5px -5px 15px 0px rgba(34, 179, 253, 0.25);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;
