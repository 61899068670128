import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { CgInstagram } from "react-icons/cg";
import ContactInfor from "../components/ContactInfor";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Footer = () =>
  // {
  //   setMainPage,
  //   handleTurnOffAudio,
  //   setLoadedMain,
  // }
  {
    return (
      <Container className="container">
        <div className="footerCol">
          <div>
            <div className="grFirst">
              <img
                alt=""
                src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/logo1_wooieo.png"
              />
              <div className="vr"></div>
              <h1>
                Join the
                <br />
                Universe
              </h1>
            </div>
            <div className="colRight">
              <ul>
                <li>
                  <NavLink to="our-story">Our story</NavLink>
                </li>
                <li>
                  <NavLink to="services">Our Service</NavLink>
                </li>
                <li>
                  <NavLink to="contact-us">Contact us</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footerCol">
          <div>
            <div className="grFirst">
              <img
                alt=""
                src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/logo2_nceygi.png"
              />
              <div className="vr"></div>
              <h1>
                Start the
                <br />
                Adventure
              </h1>
            </div>
            <div className="colRight">
              <ul>
                {/* <li>
                    <Link
                      to="/"
                      onClick={() => {
                        // sessionStorage.removeItem("aiurActive");
                        // handleTurnOffAudio();
                        setLoadedMain(true);
                        setMainPage(false);
                      }}
                    >
                      Get Start
                    </Link>
                  </li> */}
                <li>
                  <NavLink to="explore-the-cosmo">Explore The Cosmo</NavLink>
                </li>
                <li>
                  <NavLink to="cosmic-consult">Cosmic Consult</NavLink>
                </li>
              </ul>
              <Social>
                <h1>Social Links</h1>
                <ul>
                  <li>
                    <FaFacebookF />
                    Facebook
                  </li>
                  <li>
                    <FaTwitter />
                    Twitter
                  </li>
                  <li>
                    <CgInstagram />
                    Instagram
                  </li>
                </ul>
              </Social>
            </div>
          </div>
        </div>
        <div className="footerCol">
          <div>
            <div className="grFirst">
              <img
                alt=""
                src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/logo3_ujfpoq.png"
              />
              <div className="vr"></div>
              <h1>
                Follow the
                <br />
                Development
              </h1>
            </div>
            <div className="colRight">
              <ul>
                <li>
                  <NavLink to="">Home</NavLink>
                </li>
                <li>
                  <NavLink to="blog">Blog</NavLink>
                </li>
                <li>Gallery</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footerCol">
          <div>
            <div className="grFirst">
              <div style={{ width: "2.2rem" }}></div>
              <div className="vr"></div>
              <h1>Utilities</h1>
            </div>
            <div className="colRight">
              <ul>
                <li>Help</li>
                <li>Press</li>
                <li>Careers</li>
                <li>
                  <NavLink to="acknowledgements">Acknowledgements</NavLink>
                </li>
                <li>Redeem a code</li>
              </ul>
              <div className="mx-auto" style={{ width: "3.5rem" }}>
                <img
                  alt=""
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/footer-prize1_ziimqx.png"
                  className="w-100"
                />
                <img
                  alt=""
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/footer-prize2_hizhk2.png"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footerCol">
          <div>
            <div className="grFirst">
              <div style={{ width: "2.2rem" }}></div>
              <div className="vr"></div>
              <h1>Legal</h1>
            </div>
            <div className="colRight">
              <ul>
                <li>Legal</li>
                <li>
                  <NavLink to="terms-of-services">Terms of Services</NavLink>
                </li>
                <li>
                  <NavLink to="privacy-policy">Privacy Policy</NavLink>
                </li>
                <li>EULA</li>
                <li>DMCA</li>
                <li>
                  Responsible
                  <br />
                  disclosure
                </li>
                <li>
                  California notice at
                  <br />
                  collection
                </li>
                <li>
                  Do Not Sell or Share
                  <br />
                  My Personal
                  <br />
                  Information
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ContactInfor bg="rgba(37, 82, 115, 0.3)" />
      </Container>
    );
  };

export default Footer;

const Container = styled.footer`
  position: relative;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: auto;
  @media screen and (min-width: 768px) {
    grid-template-columns: auto auto auto;
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: auto auto auto auto auto;
  }
  .footerCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0 1rem;
    flex: 0 0 auto;
    width: auto;
    /* &:first-of-type {
      h1::after {
        background: linear-gradient(
          180deg,
          #1090ef 0%,
          #647553 33.33%,
          #876d4a 66.67%,
          #cc8b25 100%
        ) !important;
      }
    } */
    > div {
      clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - 0.5rem),
        calc(100% - 0.5rem) 100%,
        0 100%
      );
      .grFirst {
        height: 3rem;
        display: flex;
        align-items: center;
        gap: 0.2rem;
        > img {
          width: 2.2rem;
          height: 1.8rem;
        }
        > .vr {
          background-color: rgba(255, 255, 255, 0.6);
        }
        > h1 {
          flex-grow: 1;
          position: relative;
          font-family: "Turret Road";
          font-size: 1rem;
          font-weight: bold;
          text-align: center;
          color: bisque;
          margin: 0;
          @media all and (min-width: 768px) {
            color: #979c9f;
          }

          /* &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 3.5em;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0.6;
          background: #595959;
        } */
        }
      }
      .colRight {
        margin-left: 3.3rem;
        ul {
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;
          li {
            font-family: "Turret Road";
            font-size: 0.8em;
            font-weight: 800;
            line-height: 1.5;
            letter-spacing: 1px;
            margin: 0.4em 0;
            color: #979c9f;
            text-align: center;
            a {
              color: #979c9f;
              &.active {
                color: white;
              }
              &:hover {
                color: white;
              }
            }
          }
        }
      }
    }
  }
`;
const Social = styled.div`
  font-size: 1rem;
  margin-top: 2rem;
  h1 {
    font-family: "Turret Road";
    font-size: 0.9em;
    font-weight: 800;
    line-height: 1.25;
    letter-spacing: 1px;
    margin-bottom: 1em;
    text-align: center;
    color: bisque;
    @media all and (min-width: 768px) {
      color: #979c9f;
    }
  }
  ul {
    padding-left: 0;
    li {
      color: #979c9f;
      font-family: "Turret Road";
      font-size: 0.85em;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5em;
      margin: 1em 0;
    }
  }
`;
