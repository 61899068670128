import React from "react";

const ModalOurStoryThird = () => {
  return (
    <div className="modalOurStory">
      <h5>THE FUTURE RIGHT NOW</h5>
      <img
        alt=""
        src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/ourstory-adventurer3_c03zfq.png"
        className="w-100"
      />
      <p>
        In the year 2024, the digital landscape had undergone a transformation
        as profound as the discovery of new galaxies. Aiur Marketing, once a
        fledgling starship navigating the vast digital cosmos, had evolved into
        an interstellar beacon of progress and innovation.
        <br />
        <br />
        As the digital universe expanded, so did the challenges and
        opportunities. Aiur Marketing's Adventurers, a collective of visionaries
        and digital trailblazers, harnessed the power of emergent technologies,
        weaving through the complexities of cyberspace with unparalleled
        agility.
        <br />
        <br />
        It was a year of unprecedented change. The ancient algorithms that once
        dictated the order of the digital world were now relics, giving way to a
        new era of artificial intelligence and machine learning. Amidst this
        renaissance, The Workers of Aiur, with their indomitable spirit, stood
        at the vanguard, decoding the mysteries of the new digital epoch.
        <br />
        <br />
        As the chronicles of 2024 unfurled, they forged ahead, transforming
        every challenge into a triumph, and every vision into reality.
      </p>
    </div>
  );
};

export default ModalOurStoryThird;
