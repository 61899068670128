import React from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";

const Header = () => {
  return (
    <Container className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand ps-3">
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/logo1_wooieo.png"
            alt=""
          />
          <div>
            <h1>AIUR</h1>
            <h2>MARKETING</h2>
          </div>
        </Link>
        <button
          className="navbar-toggler pe-0"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              <Link to="/" className="navbar-brand">
                <img
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/logo1_wooieo.png"
                  alt=""
                />
                <div>
                  <h1>AIUR</h1>
                  <h2>MARKETING</h2>
                </div>
              </Link>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end flex-grow-1">
              <li className="nav-item">
                <NavLink to="services" className="nav-link">
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="our-story" className="nav-link">
                  Our Story
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="explore-the-cosmo" className="nav-link">
                  Explore The Cosmo
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="blog" className="nav-link">
                  Blog
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="contact-us" className="nav-link">
                  Contact Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="cosmic-consult" className="nav-link">
                  Cosmic Consult
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="navbar-toggler-icon"></span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <NavLink className="dropdown-item" to="terms-of-services">
                      Terms Of Services
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="privacy-policy">
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="development">
                      Development
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="acknowledgements">
                      Acknowledgements
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item moreMobile">
                <NavLink to="terms-of-services" className="nav-link">
                  Terms Of Services
                </NavLink>
              </li>
              <li className="nav-item moreMobile">
                <NavLink to="privacy-policy" className="nav-link">
                  Privacy Policy
                </NavLink>
              </li>
              <li className="nav-item moreMobile">
                <NavLink to="development" className="nav-link">
                  Development
                </NavLink>
              </li>
              <li className="nav-item moreMobile">
                <NavLink to="acknowledgements" className="nav-link">
                  Acknowledgements
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Header;

const Container = styled.nav`
  height: 3rem;
  padding: 0;
  margin: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  @media screen and (min-width: 1024px) {
    margin: 0;
    border-radius: 0;
  }
  > div {
    height: 2.8rem;
    .navbar-brand {
      display: flex;
      align-items: center;
      color: #801c1e;
      gap: 0.3rem;
      > img {
        width: 2.5rem;
      }
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        > h1 {
          font-family: "Quicksand";
          font-weight: bold;
          line-height: 1;
          margin: 0;
          font-size: 1.15rem;
          letter-spacing: 0.1rem;
        }
        > h2 {
          font-family: "Courier New";
          line-height: 1;
          margin: 0;
          font-size: 0.7rem;
        }
      }
    }
    .offcanvas {
      max-width: 70%;
      @media screen and (min-width: 1024px) {
        max-width: 100%;
      }
      .offcanvas-body {
        .nav-item {
          font-family: "Turret Road";
          &.dropdown {
            display: none;
            @media screen and (min-width: 1024px) {
              display: list-item;
            }
            .dropdown-toggle::after {
              display: none;
            }
            .dropdown-menu {
              background: #161751;
              border-radius: 0;
              clip-path: polygon(
                1rem 0,
                100% 0,
                100% calc(100% - 1rem),
                calc(100% - 1rem) 100%,
                1rem 100%,
                0 calc(100% - 1rem),
                0 1rem
              );
              &::before {
                content: "";
                width: calc(100% - 3px);
                height: calc(100% - 3px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: white;
                clip-path: polygon(
                  1rem 0,
                  100% 0,
                  100% calc(100% - 1rem),
                  calc(100% - 1rem) 100%,
                  1rem 100%,
                  0 calc(100% - 1rem),
                  0 1rem
                );
              }
              > li {
                position: relative;
              }
              .dropdown-item {
                color: #161751;
                &:hover,
                &.active,
                &:active {
                  background-color: transparent;
                  color: #007dbc;
                }
              }
            }
          }
          &.moreMobile {
            display: list-item;
            @media screen and (min-width: 1024px) {
              display: none;
            }
          }
          .nav-link {
            color: #161751;
            &:hover,
            &.active {
              color: #007dbc;
            }
          }
        }
      }
    }
  }
`;

// &::after {
//   content: "";
//   width: calc(100% - 3px);
//   height: calc(100% - 3px);
//   position: absolute;
//   background: #fff;
//   clip-path: polygon(
//     8% 0,
//     100% 0,
//     100% 0,
//     100% 92%,
//     92% 100%,
//     10% 100%,
//     0 92%,
//     0 8%
//   );
// }
