import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import star1Img from "../assets/Home/star1.svg";
// import star2Img from "../assets/Home/star2.svg";
import { TbSocial } from "react-icons/tb";
import { TbClick } from "react-icons/tb";
import { MdOutlineEmail } from "react-icons/md";
import { GoVideo } from "react-icons/go";
import { TbCircleSquare } from "react-icons/tb";
import { SiMicrostrategy } from "react-icons/si";
import { PiStrategy } from "react-icons/pi";
import { RiAdvertisementLine } from "react-icons/ri";
import { TbSeo } from "react-icons/tb";
import { RxDoubleArrowDown } from "react-icons/rx";
import Companies from "../components/Companies";
import OverlayBtn from "../components/OverlayBtn";
import Achievement from "../components/Achievement";
import Title from "../components/Title.jsx";
import TitleService from "../components/TitleService.jsx";
import MediaContent from "../components/MediaContent.jsx";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Navigation, EffectFade } from "swiper/modules";

// gsap
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const homeMainRef = useRef(null);
  const sceneStartRef = useRef(null);
  const ball3Ref = useRef(null);
  const ball4Ref = useRef(null);
  const marRef = useRef(null);
  const title1Ref = useRef(null);
  const title2Ref = useRef(null);
  const title3Ref = useRef(null);
  const searchingRef = useRef(null);
  const lookoutRef = useRef(null);
  const contTransformRef = useRef(null);
  const itemTransformRef = useRef(null);
  const ellipseRef = useRef(null);
  const astronaut1Ref = useRef(null);
  const astronaut2Ref = useRef(null);
  const bgSkyRef = useRef(null);
  const bgSpaceShipRef = useRef(null);
  const bgLandRef = useRef(null);
  const navServiceRef = useRef(null);
  const txtAiurMarketingRef = useRef(null);
  const bgVidLeftRef = useRef(null);
  const spotlight1Ref = useRef(null);
  const systemBlogsRef = useRef(null);
  const spotlight2VidRef = useRef(null);
  const spotlight2ContRef = useRef(null);
  const spotlight2Tit1Ref = useRef(null);
  const spotlight2Tit2Ref = useRef(null);
  const spotlight2BlogRef = useRef(null);
  const mediaHomeRef = useRef(null);
  const mediaContentRef = useRef(null);
  const saySomethingVidRef = useRef(null);
  const saySomethingVidInsideRef = useRef(null);
  const saySomethingImgRef = useRef(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1000);
    let mm = gsap.matchMedia();
    mm.add(
      {
        isMobile: "(max-width: 767px)",
        isTablet: "(min-width: 768px) and (max-width: 1023px)",
        isDesktop: "(min-width: 1024px)",
      },
      (context) => {
        let { isDesktop } = context.conditions;
        if (isDesktop) {
          let timeln1 = gsap.timeline({
            scrollTrigger: {
              trigger: homeMainRef.current,
              start: "top top",
              end: `+=${homeMainRef.current.offsetHeight * 10}`,
              scrub: 2,
              pin: true,
              toggleActions: "play reverse play reverse",
              markers: true,
            },
          });
          timeln1.to(title1Ref.current, {
            autoAlpha: 0,
          });
          timeln1.to(
            searchingRef.current,
            {
              autoAlpha: 1,
              duration: 3,
            },
            "+=0.5"
          );
          timeln1.to(sceneStartRef.current, {
            autoAlpha: 0,
            delay: 0.5,
          });
          timeln1.to(
            searchingRef.current,
            {
              autoAlpha: 0,
            },
            "<"
          );

          timeln1.to(
            contTransformRef.current,
            {
              autoAlpha: 1,
            },
            "<"
          );
          // action
          timeln1.to(ellipseRef.current, {
            bottom: "96%",
            onStart: function () {
              itemTransformRef.current.style.mixBlendMode = "plus-lighter";
            },
            onComplete: function () {
              itemTransformRef.current.style.mixBlendMode = "color-dodge";
            },
            onReverseComplete: function () {
              itemTransformRef.current.style.mixBlendMode = "normal";
            },
            duration: 7,
            delay: 2,
          });
          timeln1.to(
            astronaut1Ref.current,
            {
              autoAlpha: 0,
              duration: 7,
            },
            "<"
          );
          timeln1.to(
            title2Ref.current,
            {
              autoAlpha: 0,
              duration: 7,
            },
            "<"
          );
          timeln1.to(
            title3Ref.current,
            {
              autoAlpha: 1,
              duration: 7,
            },
            "<"
          );
          // action
          timeln1.to(contTransformRef.current, {
            autoAlpha: 0,
            duration: 2,
          });
          timeln1.to(
            navServiceRef.current,
            {
              autoAlpha: 1,
              duration: 2,
            },
            "<"
          );
          timeln1.to(bgSkyRef.current, {
            transform: "translateY(70%)",
            duration: 3,
          });
          timeln1.to(
            bgLandRef.current,
            {
              width: "116.46vw",
              height: "232.777vw",
              duration: 3,
            },
            "<"
          );
          timeln1.to(
            bgLandRef.current,
            {
              transform: "translateY(21%)",
              duration: 3,
            },
            "<"
          );
          timeln1.to(
            bgSpaceShipRef.current,
            {
              width: "116.46vw",
              height: "232.777vw",
              duration: 3,
            },
            "<"
          );
          timeln1.to(
            bgSpaceShipRef.current,
            {
              transform: "translateY(21%)",
              duration: 3,
            },
            "<"
          );
          timeln1.to(
            astronaut2Ref.current,
            {
              left: "2vw",
              width: "18vw",
              height: "50vw",
              duration: 3,
            },
            "<"
          );
          timeln1.to(
            txtAiurMarketingRef.current,
            {
              fontSize: "10vw",
              duration: 2,
              delay: 1,
            },
            "<"
          );
          timeln1.to(
            txtAiurMarketingRef.current,
            {
              y: "-11vw",
              duration: 2,
              delay: 1,
            },
            "<"
          );
          timeln1.to(astronaut2Ref.current, {
            left: "9vw",
            width: "15vw",
            height: "41.67vw",
            duration: 3.5,
            delay: 1,
          });
          timeln1.to(
            bgSkyRef.current,
            {
              bottom: 0,
              transform: "translateY(12%)",
              duration: 3.5,
            },
            "<"
          );
          timeln1.to(
            bgLandRef.current,
            {
              bottom: 0,
              width: "100vw",
              height: "200vw",
              duration: 3.5,
            },
            "<"
          );
          timeln1.to(
            bgLandRef.current,
            {
              bottom: 0,
              transform: "translateY(12%)",
              duration: 3.5,
            },
            "<"
          );
          timeln1.to(
            bgSpaceShipRef.current,
            {
              bottom: 0,
              width: "100vw",
              height: "200vw",
              duration: 3.5,
            },
            "<"
          );
          timeln1.to(
            bgSpaceShipRef.current,
            {
              bottom: 0,
              transform: "translateY(12%)",
              duration: 3.5,
            },
            "<"
          );
          timeln1.to(
            txtAiurMarketingRef.current,
            {
              fontSize: "8vw",
              zIndex: 10,
              xPercent: 10,
              duration: 3.5,
            },
            "<"
          );
          timeln1.to(bgSkyRef.current, {
            width: "100%",
            height: "100%",
            transform: "none",
            duration: 3,
          });
          timeln1.to(
            bgLandRef.current,
            {
              width: "100%",
              height: "100%",
              transform: "none",
              duration: 3,
            },
            "<"
          );
          timeln1.to(
            bgSpaceShipRef.current,
            {
              width: "100%",
              height: "100%",
              transform: "none",
              duration: 3,
            },
            "<"
          );
          timeln1.to(
            astronaut2Ref.current,
            {
              left: "8.5%",
              width: "15%",
              height: "18%",
              bottom: "6.3%",
              top: "unset",
              duration: 3,
            },
            "<"
          );
          timeln1.to(
            txtAiurMarketingRef.current,
            {
              fontSize: "5vw",
              top: 0,
              y: 0,
              xPercent: 0,
              transform: "translate(78%, 0)",
              duration: 3,
            },
            "<"
          );
          timeln1.to(
            ".txtAiurMarketing span",
            {
              color: "#7DDBF9",
              duration: 3,
            },
            "<"
          );
          timeln1.to(
            bgVidLeftRef.current,
            {
              autoAlpha: 1,
            },
            ">"
          );
          // action
          timeln1.to(navServiceRef.current, {
            yPercent: -100,
            duration: 3,
            delay: 0.8,
          });
          timeln1.to(
            spotlight1Ref.current,
            {
              top: "3rem",
              duration: 3,
            },
            "<"
          );
          // action
          timeln1.to(spotlight1Ref.current, {
            xPercent: -100,
            duration: 3.5,
            delay: 1,
          });
          timeln1.to(
            systemBlogsRef.current,
            {
              left: 0,
              duration: 3.5,
            },
            "<"
          );
          // action
          timeln1.to(spotlight2VidRef.current, {
            left: "10%",
            top: "15vh",
            height: "60vh",
            width: "50%",
            clipPath:
              "polygon(20% 0, 80% 0%, 100% 20%, 100% 100%, 80% 100%, 20% 100%, 0% 80%, 0 0)",
            duration: 3,
            delay: 1,
          });
          timeln1.to(
            spotlight2ContRef.current,
            {
              autoAlpha: 0,
              duration: 2,
            },
            "<"
          );
          timeln1.to(
            spotlight2BlogRef.current,
            {
              left: "70%",
              duration: 3,
            },
            "<"
          );
          // action
          timeln1.to(spotlight2BlogRef.current, {
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            padding: "1em",
            backgroundColor: "#161751",
            duration: 3,
            delay: 1,
          });
          timeln1.to(systemBlogsRef.current, {
            yPercent: -100,
            duration: 3.5,
            delay: 1,
          });
          timeln1.to(
            mediaHomeRef.current,
            {
              autoAlpha: 1,
              duration: 0,
            },
            "<"
          );
          timeln1.to(
            mediaContentRef.current,
            {
              top: "15vh",
              duration: 3,
            },
            "<"
          );
          timeln1.to(mediaHomeRef.current, {
            left: "100%",
            duration: 3,
            delay: 1.5,
          });
          timeln1.to(
            saySomethingVidRef.current,
            {
              left: 0,
              duration: 3,
            },
            "<"
          );
          // action
          timeln1.to(saySomethingVidInsideRef.current, {
            clipPath: `polygon( 
              100% 0vh,
              100% 0vh,
              100% 100%,
              0% 100%,
              0% 100%,
              0% 0vh)`,
            delay: 1,
            duration: 3,
          });
          // action
          timeln1.to(saySomethingVidRef.current, {
            top: "unset",
            bottom: 0,
            delay: 2,
            duration: 2,
          });
          // action
          timeln1.to(saySomethingVidRef.current, {
            left: "-100%",
            delay: 2,
            duration: 4,
          });
          timeln1.to(
            saySomethingImgRef.current,
            {
              left: 0,
              duration: 4,
            },
            "<"
          );
          timeln1.to(
            saySomethingImgRef.current,
            {
              duration: 1,
            },
            ">"
          );
        }
      }
    );
    window.scrollTo({ top: 0, behavior: "auto" });
    ScrollTrigger.refresh();
    return () => {
      ScrollTrigger.getAll().forEach((t) => {
        t.scroll(0);
        t.kill(true);
      });
    };
  }, []);

  return (
    <Container ref={homeMainRef}>
      <div id="stars-containerBg">
        <div id="starsBg"></div>
        <div id="stars2Bg"></div>
        <div id="stars3Bg"></div>
      </div>
      <Swiper
        navigation={true}
        effect={"fade"}
        spaceBetween={0}
        slidesPerView={1}
        breakpoints={{
          1024: {
            slidesPerView: 2,
          },
        }}
        modules={[Navigation, EffectFade]}
        className="mySwiperTopHome"
      >
        <SwiperSlide>
          <div className="sceneStart" ref={sceneStartRef}>
            <svg
              ref={ball3Ref}
              className="ball3"
              xmlns="http://www.w3.org/2000/svg"
              width="332"
              height="335"
              viewBox="0 0 332 335"
              fill="none"
            >
              <g
                style={{
                  mixBlendMode: "screen",
                }}
              >
                <path
                  d="M3.43014 133.564C21.9401 43.2835 110.16 -14.9365 200.49 3.58353C290.77 22.0835 348.98 110.314 330.48 200.594C311.97 290.914 223.75 349.114 133.47 330.614C43.1301 312.094 -15.0799 223.884 3.43014 133.564Z"
                  fill="url(#paint0_radial_915_18947)"
                />
              </g>
              <g
                style={{
                  mixBlendMode: "color-dodge",
                }}
              >
                <g
                  style={{
                    mixBlendMode: "screen",
                  }}
                >
                  <path
                    d="M3.43016 133.563C21.9402 43.2728 110.16 -14.9372 200.49 3.57282C290.77 22.0728 348.98 110.303 330.48 200.593C311.97 290.913 223.75 349.123 133.46 330.613C43.1302 312.103 -15.0798 223.883 3.43016 133.563Z"
                    fill="url(#paint1_radial_915_18947)"
                  />
                </g>
              </g>
              <defs>
                <radialGradient
                  id="paint0_radial_915_18947"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(290.66 11.4823) rotate(-168.42) scale(437.21 437.21)"
                >
                  <stop offset="0.58" />
                  <stop offset="0.61" stopColor="#050301" />
                  <stop offset="0.66" stopColor="#160E04" />
                  <stop offset="0.71" stopColor="#322009" />
                  <stop offset="0.74" stopColor="#442C0D" />
                  <stop offset="0.81" stopColor="#805A23" />
                </radialGradient>
                <radialGradient
                  id="paint1_radial_915_18947"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(317.834 -22.6993) rotate(-168.42) scale(533.23 533.23)"
                >
                  <stop />
                  <stop offset="0.26" stopColor="#100503" />
                  <stop offset="0.28" stopColor="#150506" />
                  <stop offset="0.3" stopColor="#26080F" />
                  <stop offset="0.33" stopColor="#420D1E" />
                  <stop offset="0.35" stopColor="#691333" />
                  <stop offset="0.39" stopColor="#9B1C4E" />
                  <stop offset="0.42" stopColor="#D7266F" />
                  <stop offset="0.43" stopColor="#ED2A7B" />
                  <stop offset="0.46" stopColor="#D2256D" />
                  <stop offset="0.54" stopColor="#9B1B50" />
                  <stop offset="0.62" stopColor="#6B1338" />
                  <stop offset="0.7" stopColor="#450C23" />
                  <stop offset="0.78" stopColor="#260614" />
                  <stop offset="0.85" stopColor="#110308" />
                  <stop offset="0.93" stopColor="#040002" />
                  <stop offset="0.99" />
                </radialGradient>
              </defs>
            </svg>
            <svg
              ref={ball4Ref}
              className="ball4"
              xmlns="http://www.w3.org/2000/svg"
              width="434"
              height="477"
              viewBox="0 0 434 477"
              fill="none"
            >
              <g
                style={{
                  mixBlendMode: "screen",
                }}
              >
                <path
                  d="M41.7231 113.532C-34.9949 229.798 -2.91824 386.318 113.418 463.078C229.698 539.796 386.218 507.706 462.936 391.44C539.682 275.118 507.606 118.612 391.326 41.8938C274.976 -34.8525 118.469 -2.78996 41.7231 113.532Z"
                  fill="url(#paint0_radial_915_18957)"
                />
              </g>
              <g
                style={{
                  mixBlendMode: "color-dodge",
                }}
              >
                <g
                  style={{
                    mixBlendMode: "screen",
                  }}
                >
                  <path
                    d="M41.7235 113.532C-34.9945 229.812 -2.93196 386.333 113.404 463.093C229.684 539.811 386.19 507.734 462.922 391.455C539.669 275.133 507.592 118.626 391.326 41.8942C274.99 -34.8662 118.47 -2.78958 41.7235 113.532Z"
                    fill="url(#paint1_radial_915_18957)"
                  />
                </g>
              </g>
              <defs>
                <radialGradient
                  id="paint0_radial_915_18957"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(218.34 550.961) rotate(-146.58) scale(660.847 660.847)"
                >
                  <stop offset="0.58" />
                  <stop offset="0.61" stopColor="#000305" />
                  <stop offset="0.65" stopColor="#000D16" />
                  <stop offset="0.69" stopColor="#001E32" />
                  <stop offset="0.74" stopColor="#003456" />
                  <stop offset="0.83" stopColor="#005664" />
                  <stop offset="1" stopColor="#00937E" />
                </radialGradient>
                <radialGradient
                  id="paint1_radial_915_18957"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(210.848 616.522) rotate(-146.58) scale(805.991 805.991)"
                >
                  <stop />
                  <stop offset="0.26" stopColor="#100503" />
                  <stop offset="0.28" stopColor="#0F0A08" />
                  <stop offset="0.31" stopColor="#0D1B16" />
                  <stop offset="0.35" stopColor="#0A372E" />
                  <stop offset="0.39" stopColor="#055E50" />
                  <stop offset="0.42" stopColor="#008F7A" />
                  <stop offset="0.43" stopColor="#00937E" />
                  <stop offset="0.51" stopColor="#006B5C" />
                  <stop offset="0.62" stopColor="#00453B" />
                  <stop offset="0.71" stopColor="#002621" />
                  <stop offset="0.81" stopColor="#00110E" />
                  <stop offset="0.91" stopColor="#000403" />
                  <stop offset="0.99" />
                </radialGradient>
              </defs>
            </svg>
            <div ref={title1Ref} className="title1 h4">
              SCAN THE PLANET'S SURFACE
              <h5>Coordinates of the planet: 0325648:212346</h5>
            </div>
            <Mars ref={marRef}>
              <img
                className="mars"
                src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-mars1_ohoux5.png"
                alt=""
              />
              <div className="inner">
                <img
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-scan_pisofa.gif"
                  alt=""
                />
                <h6>SCROLL DOWN</h6>
                <RxDoubleArrowDown />
              </div>
              <div className="saterlite-fire">
                <img
                  className="fire"
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-fire_ykdnxz.gif"
                  alt=""
                />
                <img
                  className="saterlite"
                  src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/intro-saterlite_tgoadx.png"
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                />
              </div>
              <img
                className="beam"
                src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-beam_fsei4z.png"
                alt=""
              />
            </Mars>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <Searching ref={searchingRef}>
            <div className="text">
              <ul>
                <li>
                  <span>Color: brown, gold, and tan.</span>
                </li>
                <li>
                  <span>Water: 20%</span>
                </li>
                <li>
                  <span>
                    Minerals: Iron, Gold, olivine, pyroxenes, hematite,
                    magnetite, gypsum, and phyllosilicates
                  </span>
                </li>
                <li>
                  <span>Atmosphere: 30 degree</span>
                </li>
                <li>
                  <span>Oxigen: 30%</span>
                </li>
                <li>
                  <span>...loading...</span>
                </li>
              </ul>
            </div>
            <div className="mars2-strange">
              <img
                className="mars2"
                src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-mars2_rplryj.gif"
                alt=""
              />
              <img
                className="strange"
                src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-strange_uswi4j.gif"
                alt=""
              />
            </div>
            <div className="searchTitle">Searching...</div>
          </Searching>
        </SwiperSlide>
      </Swiper>
      <div ref={contTransformRef} className="contTransform">
        <img
          ref={lookoutRef}
          className="lookout"
          src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/intro-gate_hmh48v.png"
          alt=""
        />
        <div>
          <div className="text">
            <div ref={title2Ref} className="h5">
              TELEPORT IS READY
              <h6>Location: AIUR City</h6>
            </div>
            <div
              ref={title3Ref}
              className="title3 h5"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              TELEPORTED
              <h6>Success</h6>
            </div>
          </div>
          <div ref={itemTransformRef} className="itemTransform">
            <div className="podium-top">
              <img
                className="rectangle"
                src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-rectangle_x1zpmy.png"
                alt=""
              />
              <img
                ref={astronaut1Ref}
                className="astronaut1"
                src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-astronaut_hmwt2f.png"
                alt=""
              />
              <div ref={ellipseRef} className="ellipse"></div>
            </div>
            <img
              className="podium"
              src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-podium_tybg4r.png"
              alt=""
            />
          </div>
          <div className="rectangleRight">
            <Link
              className="rectangleRightItem"
              to="contact-us"
              style={{
                transform: "translateX(23%)",
              }}
            >
              Let's Talk
              <svg
                viewBox="0 0 431.818 100"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              >
                <defs>
                  <linearGradient id="grad1">
                    <stop stopColor="#007DBC" offset="0" />
                    <stop stopColor="#FBBC04" offset="1" />
                  </linearGradient>
                </defs>
                <polygon
                  points="90.911 0,
                      431.818 0,
                      431.818 54.545,
                      340.9075 100,
                      0 100,
                      0 54.545"
                  fill="none"
                  stroke="url(#grad1)"
                  strokeWidth="1rem"
                />
              </svg>
            </Link>
            <Link className="rectangleRightItem" to="cosmic-consult">
              Get A Quote
              <svg
                viewBox="0 0 431.818 100"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              >
                <polygon
                  points="90.911 0,
                      431.818 0,
                      431.818 54.545,
                      340.9075 100,
                      0 100,
                      0 54.545"
                  fill="none"
                  stroke="url(#grad1)"
                  strokeWidth="1rem"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
      <NavService ref={navServiceRef}>
        <div className="groupLeft">
          <img
            ref={bgSkyRef}
            className="bgSky"
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-sky_olq9i0.png"
            alt=""
          />
          <h1 ref={txtAiurMarketingRef} className="txtAiurMarketing">
            AIUR <span>MARKETING</span>
          </h1>
          <img
            ref={bgLandRef}
            className="bgLand"
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-land_b66o2e.png"
            alt=""
          />
          <img
            ref={bgSpaceShipRef}
            className="bgSpaceShip"
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-spaceship_dpoxvw.png"
            alt=""
          />
          <img
            ref={astronaut2Ref}
            className="astronaut2"
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-astronaut_hmwt2f.png"
            alt=""
          />
          <BgVidLeft ref={bgVidLeftRef} loop muted autoPlay>
            <source
              src="https://res.cloudinary.com/db85dpvaz/video/upload/aiur-marketing/home-bgLeft_ixwioy.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </BgVidLeft>
        </div>
        <div className="content">
          <h3>NAVIGATE THE DIGITAL FRONTIER WITH PRECISION AND CREATIVITY</h3>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            navigation={true}
            breakpoints={{
              464: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            modules={[Navigation]}
          >
            <SwiperSlide>
              <TitleService
                icon={<TbSocial />}
                title="Social Media Management"
              />
              <TitleService
                icon={<GoVideo />}
                title="Video Production & Editing"
              />
              <TitleService
                icon={<PiStrategy />}
                title="Social Media Strategy"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TitleService
                icon={<TbClick />}
                title="Pay-Per-Click Advertising (PPC)"
              />
              <TitleService
                icon={<TbCircleSquare />}
                title="Infographics & Visual Content"
              />
              <TitleService
                icon={<RiAdvertisementLine />}
                title="Social Media Advertising"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TitleService
                icon={<MdOutlineEmail />}
                title="Email Marketing Campaigns"
              />
              <TitleService
                icon={<SiMicrostrategy />}
                title="Content Strategy Development"
              />
              <TitleService icon={<TbSeo />} title="SEO Development" />
            </SwiperSlide>
          </Swiper>
          <Link to="services">
            <OverlayBtn text="Our Service" />
          </Link>
        </div>
      </NavService>
      <div ref={spotlight1Ref} className="spotlight1">
        <video className="bgVid" loop muted autoPlay>
          <source
            src="https://res.cloudinary.com/db85dpvaz/video/upload/aiur-marketing/spotlight1Vid_kz31io.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="wrapper">
          <div className="title">
            <img
              src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/logo1_wooieo.png"
              alt=""
            />
            <h3>
              Absorb the Spotlight,
              <br />
              Accelerate Your Business.
            </h3>
          </div>
          <div className="content">
            <h3>Achievement</h3>
            <div className="mid">
              <h4>Empowering Success, One Milestone at a Time</h4>
              <Achievement borderColor="#7DDBF9" />
            </div>
            <Link to="our-story">
              <OverlayBtn text="See More" />
            </Link>
          </div>
        </div>
      </div>
      <SystemBlogs ref={systemBlogsRef}>
        <div ref={spotlight2Tit1Ref} className="spotlight2Tit1">
          <Title h2span="NAME" h2="SYSTEM" color="#7DDBF9" />
        </div>
        <video
          ref={spotlight2VidRef}
          className="spotlight2Vid"
          loop
          muted
          autoPlay
        >
          <source
            src="https://res.cloudinary.com/db85dpvaz/video/upload/aiur-marketing/spotlight2Vid_gselwd.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <ContCompanies ref={spotlight2ContRef}>
          <Companies />
        </ContCompanies>
        <div ref={spotlight2Tit2Ref} className="spotlight2Tit2">
          <Title h2span="NEWS" h2="BLOGS" color="#7DDBF9" />
        </div>
        <Swiper
          navigation={true}
          spaceBetween={0}
          slidesPerView={1}
          breakpoints={{
            464: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              spaceBetween: 10,
              slidesPerView: 3,
            },
          }}
          modules={[Navigation]}
          className="spotlight2Blog"
          ref={spotlight2BlogRef}
        >
          <SwiperSlide
            className="item"
            style={{
              background: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-blog1-bg_xkknbt.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="text">
              <h4>BRAINSTORMS TO BREAKTHROUGHS</h4>
              <p>
                Aiur's creative engine is where innovative marketing strategies
                are born. Our latest brainstorming session has paved the way for
                a groundbreaking campaign that marries data with creativity.
                Discover how our team's diverse perspectives forge the
                unforgettable narratives that captivate audiences worldwide.
              </p>
              <Link to="#"> &gt; READ MORE</Link>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="item"
            style={{
              background: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-blog2-bg_nsbe4n.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="text">
              <h4>
                DECODING DATA
                <br />
                INTO STRATEGY
              </h4>
              <p>
                Data is the compass that guides Aiur's marketing voyages. With
                our advanced analytical dashboards, we decode complex metrics
                into actionable insights. Step into the control room where every
                graph and chart steers our clients towards uncharted territories
                of market dominance.
              </p>
              <Link to="#"> &gt; READ MORE</Link>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="item"
            style={{
              background: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-blog3-bg_ltww4p.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="text">
              <h4>THE FUTURE OF CLIENT PRESENTATIONS</h4>
              <p>
                Aiur is redefining client presentations with interactive digital
                storytelling. Witness how our latest client strategy session
                turned into an immersive experience, showcasing our commitment
                to not just meet but exceed client expectations with
                presentations that are as engaging as they are enlightening
              </p>
              <Link to="#"> &gt; READ MORE</Link>
            </div>
          </SwiperSlide>
        </Swiper>
      </SystemBlogs>
      <div ref={mediaHomeRef} className="mediaHome">
        <div className="title">
          <Title h2span="MEDIA STORAGE" h2="SYSTEM" color="#7DDBF9" />
        </div>
        <MediaContent forwardedRef={mediaContentRef} />
      </div>
      <div ref={saySomethingVidRef} className="saySomethingVid">
        <div className="title">
          <Title h2span="NAME" h2="SYSTEM" color="#7DDBF9" />
        </div>
        <div ref={saySomethingVidInsideRef} className="vid">
          <video
            loop
            muted
            autoPlay
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
            }}
          >
            <source
              src="https://res.cloudinary.com/db85dpvaz/video/upload/aiur-marketing/saySomethingVid_hhd1jb.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="text">
            <h6>
              VELARI, THE LUMINOUS CAPITAL OF AIUR, MIRRORS THE INNOVATIVE
              SPIRIT, WHERE ANCIENT WISDOM FUELS CUTTING-EDGE CAMPAIGNS AMONG
              THE STARS.
            </h6>
            <h6>
              IN VELARI'S RADIANT SHADOW, AIUR MARKETING CRAFTS STORIES THAT
              TRANSCEND TIME, BLENDING TRADITION WITH TECHNOLOGY TO LAUNCH
              BRANDS INTO THE COSMOS.
            </h6>
          </div>
        </div>
      </div>
      <div
        ref={saySomethingImgRef}
        className="saySomethingImg"
        style={{
          background: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-saysomething-pic_tjvmxt.png") center bottom / cover`,
        }}
      >
        <h6>
          AT AIUR MARKETING, OUR TEAM IS A COALITION OF VISIONARIES, EACH A
          SPECIALIST IN THEIR FIELD, UNITING UNDER THE AIUR BANNER TO FORGE
          CAMPAIGNS AS DYNAMIC AND DIVERSE AS THE CITIZENS OF VELARI. TOGETHER,
          WE NAVIGATE THE VAST DIGITAL TERRAINS TO CHART NEW TERRITORIES IN
          MARKETING EXCELLENCE.
          <Link to="explore-the-cosmo">SEE MORE</Link>
        </h6>
      </div>
    </Container>
  );
};

export default Home;

const Container = styled.section`
  position: relative;
  @media all and (min-width: 1024px) {
    height: 100vh;
    overflow: hidden;
  }
  .mySwiperTopHome {
    height: 100vh;
    width: 100%;
    z-index: 0;
    mix-blend-mode: screen;
    padding-top: 4rem;
    @media all and (min-width: 1024px) {
      padding-top: 3rem;
      height: 100%;
      position: absolute;
    }
    .swiper-slide {
      @media all and (min-width: 1024px) {
        position: absolute;
        width: 100% !important;
      }
    }
    .sceneStart {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: visible;
      @media all and (min-width: 1024px) {
        position: absolute;
        top: 0;
        left: 0;
      }
      .ball3 {
        position: absolute;
        top: -4%;
        right: 0;
        width: 14vw;
        mix-blend-mode: color-dodge;
      }
      .ball4 {
        position: absolute;
        bottom: -15%;
        right: 0;
        width: 18vw;
        mix-blend-mode: color-dodge;
      }
      .title1 {
        text-align: center;
        padding-top: 2rem;
        opacity: 1;
        visibility: visible;
        color: #fbbc04;
        font-weight: 700;
        line-height: 1.5;
        h5 {
          color: #7ddbf9;
          font-family: "Turret Road";
          font-weight: 800;
          letter-spacing: 1px;
          margin: 0;
        }
      }
    }
  }
  .contTransform {
    width: 100%;
    margin: 0;
    padding: 6rem 0;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (min-width: 1024px) {
      height: calc(100vh - 3rem);
      position: absolute;
      top: 3rem;
      left: 0;
      padding: 0;
      align-items: flex-end;
      opacity: 0;
      visibility: hidden;
    }
    .lookout {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 105%;
      width: auto;
      mix-blend-mode: plus-lighter;
      @media screen and (min-width: 768px) {
        height: auto;
        width: 100%;
      }
      @media all and (min-width: 1024px) {
        height: auto;
        width: 122%;
      }
    }
    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .text {
        width: 100%;
        position: relative;
        font-size: 1rem;
        > div {
          white-space: nowrap;
          opacity: 1;
          visibility: visible;
          color: #fbbc04;
          text-align: center;
          font-weight: bold;
          letter-spacing: 1px;
          margin: 0;
          h6 {
            color: #7ddbf9;
            text-align: center;
            font-family: "Turret Road";
            font-weight: bold;
            letter-spacing: 1px;
            margin: 0;
          }
          &.title3 {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
      .itemTransform {
        width: 14rem;
        mix-blend-mode: normal;
        position: relative;
        @media all and (min-width: 1024px) {
          width: 18rem;
        }
        @media all and (min-width: 1920px) {
          width: 25rem;
        }
        .podium-top {
          position: relative;
          width: 64%;
          margin: 0 auto -2%;
          .astronaut1 {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 95%;
            opacity: 1;
            visibility: visible;
          }
          .rectangle {
            width: 100%;
            height: 100%;
          }
          .ellipse {
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translateY(50%);
            width: 100%;
            height: 18%;
            background: rgba(0, 125, 188, 0.3);
            clip-path: ellipse(50% 20% at 50% 50%);
          }
        }
        .podium {
          display: block;
          width: 100%;
        }
      }
      .rectangleRight {
        position: relative;
        bottom: 15%;
        right: 0;
        transform: none;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        @media all and (min-width: 1024px) {
          position: absolute;
          transform: translate(100%, 0);
        }
        .rectangleRightItem {
          position: relative;
          width: 9.5rem;
          height: 2.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #7ddbf9;
          font-family: "Turret Road";
          font-weight: 700;
          line-height: 1;
          letter-spacing: 1px;
          background: dimgray;
          @media all and (min-width: 1024px) {
            background: rgba(255, 255, 255, 0.3);
          }
          clip-path: polygon(
            21.053% 0,
            100% 0,
            100% 54.545%,
            78.947% 100%,
            0 100%,
            0 54.545%
          );
          &:hover {
            background: #007dbc;
          }
        }
      }
    }
  }
  /* .star42 {
    position: absolute;
    top: 61%;
    left: 57%;
    width: 35vw;
    mix-blend-mode: screen;
    opacity: 0.5;
    visibility: visible;
  } */
  .spotlight1 {
    position: relative;
    width: 100%;
    @media screen and (min-width: 1024px) {
      height: calc(100vh - 3rem);
      position: absolute;
      top: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bgVid {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 100% 100%;
    }
    .wrapper {
      position: relative;
      width: 100%;
      @media all and (min-width: 1024px) {
        width: 60%;
      }
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 1rem 0.5rem;
        @media all and (min-width: 1024px) {
          flex-direction: row;
        }
        img {
          width: 12vw;
          @media all and (min-width: 1024px) {
            width: 7vw;
          }
        }
        h3 {
          color: #fbbc04;
          font-family: "Turret Road";
          font-weight: bold;
          margin: 0;
          text-align: center;
        }
      }
      .content {
        width: 100%;
        text-align: center;
        background: rgba(22, 23, 81, 0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        h3 {
          color: #fbbc04;
          font-family: "Turret Road";
          font-weight: bold;
          margin: 0;
          line-height: 1;
        }
        .mid {
          width: 100%;
          > h4 {
            color: #7ddbf9;
            text-shadow: 0px 0px 3px #fbbc04;
          }
        }
      }
    }
  }
  .mediaHome {
    width: 100%;
    position: relative;
    opacity: 1;
    visibility: visible;
    margin-top: 3rem;
    @media screen and (min-width: 1024px) {
      margin-top: 0;
      height: calc(100vh - 3rem);
      position: absolute;
      top: 3rem;
      left: 0;
      justify-content: flex-start;
      opacity: 0;
      visibility: hidden;
      padding: 0 7rem;
    }
    .title {
      display: flex;
      margin-left: 0;
      justify-content: center;
      @media screen and (min-width: 1024px) {
        position: absolute;
        margin-left: 2rem;
        height: 15vh;
        align-items: flex-end;
      }
    }
  }
  .saySomethingVid {
    position: relative;
    width: 100%;
    margin-top: 3rem;
    @media screen and (min-width: 1024px) {
      margin-top: 0;
      position: absolute;
      top: 3rem;
      left: -100%;
      bottom: unset;
      height: 73vw;
    }
    .title {
      position: relative;
      display: flex;
      justify-content: center;
      @media screen and (min-width: 1024px) {
        position: absolute;
        left: 25%;
        top: 0;
        height: 15vh;
        align-items: flex-end;
      }
    }
    .vid {
      position: relative;
      @media all and (min-width: 1024px) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        clip-path: polygon(
          65% 15vh,
          75% 25vh,
          75% 75vh,
          35% 75vh,
          25% 65vh,
          25% 15vh
        );
      }
      .text {
        height: 100%;
        position: relative;
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 11rem;
        > h6 {
          color: #fff;
          text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
          font-weight: bold;
          line-height: 1.4;
          letter-spacing: 1px;
          max-width: 100%;
          @media all and (min-width: 1024px) {
            max-width: 50%;
          }
          &:first-of-type {
            margin-left: auto;
          }
        }
      }
    }
  }
  .saySomethingImg {
    position: relative;
    width: 100%;
    padding: 2rem 1rem;
    margin: 0;
    @media all and (min-width: 1024px) {
      position: absolute;
      top: 3rem;
      left: 100%;
      height: calc(100vh - 3rem);
    }
    h6 {
      background: rgba(0, 125, 188, 0.5);
      color: #fff;
      text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: 1px;
      padding: 1rem;
      max-width: 100%;
      @media all and (min-width: 1024px) {
        max-width: 51%;
      }
      a {
        color: #fff;
        display: block;
        text-align: right;
        margin-top: 0.5em;
      }
    }
  }
`;
const Mars = styled.div`
  --w-h: 55vw;
  width: var(--w-h);
  height: var(--w-h);
  position: absolute;
  left: -5%;
  top: 50%;
  transform: translateY(-50%);
  mix-blend-mode: lighten;
  opacity: 1;
  visibility: visible;
  @media all and (min-width: 1024px) {
    top: 61%;
    --w-h: 45vw;
  }
  .mars {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    mix-blend-mode: lighten;
  }
  .inner {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border-radius: 50%;
    mix-blend-mode: plus-lighter;
    img {
      width: 160%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      mix-blend-mode: plus-lighter;
    }
    h6 {
      display: none;
      color: #fff;
      filter: drop-shadow(0px 0px 6px #fbbc04);
      font-family: "Turret Road";
      font-weight: 700;
      line-height: 1;
      letter-spacing: 3px;
      margin-top: 65%;
      @media all and (min-width: 1024px) {
        display: block;
      }
    }
    svg {
      width: 2rem;
      height: 2rem;
      stroke-width: 1px;
      color: #aa9bff;
      display: none;
      @media screen and (min-width: 1024px) {
        display: inline-block;
      }
    }
  }
  .saterlite-fire {
    position: absolute;
    top: 39%;
    right: -50%;
    transform: translate(70%, -50%);
    width: 15vw;
    mix-blend-mode: plus-lighter;
    .fire {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 11vw;
      mix-blend-mode: screen;
    }
  }
  .beam {
    position: absolute;
    top: 43%;
    right: -50%;
    transform-origin: top right;
    transform: rotate(41deg);
    width: 30vw;
    mix-blend-mode: lighten;
  }
`;
const Searching = styled.div`
  background: url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-research-framein_n4gth0.jpg")
    center / 100% 100% no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  border-width: 1rem 1rem 2rem;
  border-style: solid;
  background-color: transparent;
  padding: 2rem 0.5rem;
  border-image-source: url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-research-frameout_tl1jhm.png);
  border-image-repeat: stretch;
  border-image-slice: 17%;
  border-image-width: 4rem;
  @media all and (min-width: 1024px) {
    width: 35rem;
    opacity: 0;
    visibility: hidden;
  }
  .text {
    color: #fff;
    font-family: "Turret Road";
    line-height: 1.4;
    letter-spacing: 1px;
    ul {
      padding: 0 !important;
      margin-left: 1em !important;
      margin-bottom: 0 !important;
      list-style-type: disc;
    }
  }
  .mars2-strange {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 5rem;
    overflow: hidden;
    .mars2 {
      height: 100%;
      width: 30%;
      object-fit: contain;
      mix-blend-mode: lighten;
    }
    .strange {
      height: 100%;
      width: 55%;
      object-fit: contain;
      mix-blend-mode: lighten;
    }
  }
  .searchTitle {
    position: absolute;
    left: 50%;
    bottom: 1%;
    transform: translateX(-50%);
    color: #fff;
    font-family: "Turret Road";
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 1px;
  }
`;
const SystemBlogs = styled.div`
  position: relative;
  width: 100%;
  @media all and (min-width: 1024px) {
    position: absolute;
    height: calc(100vh - 3rem);
    top: 3rem;
    left: 100%;
    z-index: 1;
  }
  .spotlight2Tit1 {
    position: relative;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    @media all and (min-width: 1024px) {
      margin-top: 0;
      position: absolute;
      top: 0;
      left: 10%;
      height: 15vh;
      align-items: flex-end;
    }
  }
  .spotlight2Tit2 {
    position: relative;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    @media all and (min-width: 1024px) {
      margin-top: 0;
      position: absolute;
      top: 0;
      left: 70%;
      height: 15vh;
      align-items: flex-end;
    }
  }
  .spotlight2Vid {
    display: none;
    @media all and (min-width: 1024px) {
      display: block;
      clip-path: polygon(
        0 0,
        100% 0,
        100% 0,
        100% 100%,
        100% 100%,
        0 100%,
        0 100%,
        0 0
      );
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 100% 100%;
      z-index: 1;
    }
  }
  .spotlight2Blog {
    position: relative;
    width: 100%;
    height: 28rem;
    padding: 0;
    background-color: transparent;
    @media all and (min-width: 1024px) {
      position: absolute;
      z-index: 1;
      top: 15vh;
      left: 100%;
      width: 25vw;
      height: 60vh;
    }
    .item {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      @media all and (min-width: 1024px) {
        width: 32vw;
        &:hover {
          .text {
            bottom: 0;
            transform: translateY(0%);
          }
        }
      }
      .text {
        width: 100%;
        position: relative;
        transition: 0.3s;
        padding: 2em 1em;
        background: linear-gradient(
          180deg,
          rgba(0, 125, 188, 0) 0%,
          rgba(1, 103, 156, 0.9) 100%
        );
        @media all and (min-width: 1024px) {
          position: absolute;
          left: 0;
          bottom: 5rem;
          transform: translateY(100%);
        }
        > h4 {
          color: #fefafa;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-weight: 600;
          line-height: 1.3;
          letter-spacing: -0.6px;
        }
        > p {
          color: #fff;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-weight: bold;
          line-height: 1.4;
          letter-spacing: -0.36px;
          padding: 0.4em 0;
          margin-bottom: 0;
        }
        a {
          color: #fbbc04;
          font-weight: 700;
          line-height: 1;
          letter-spacing: -0.3px;
        }
      }
    }
  }
`;
const NavService = styled.div`
  width: 100%;
  position: relative;
  @media all and (min-width: 1024px) {
    height: calc(100vh - 3rem);
    display: flex;
    position: absolute;
    top: 3rem;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }
  .groupLeft {
    width: 100%;
    height: 165vw;
    position: relative;
    @media all and (min-width: 1024px) {
      width: 25vw !important;
      height: 100%;
      display: block;
      z-index: 1;
    }
    .bgSky {
      position: absolute;
      bottom: 100%;
      left: 0;
      transform: translateY(86%);
      width: 100vw;
      height: 200vw;
      display: none;
      @media all and (min-width: 1024px) {
        display: block;
      }
    }
    .txtAiurMarketing {
      width: 100%;
      height: 100%;
      text-align: center;
      position: absolute;
      top: 5%;
      color: #fff;
      font-family: "Tulpen One";
      font-size: 8vw;
      margin: 0;
      line-height: 1;
      letter-spacing: 1px;
      white-space: nowrap;
      z-index: 1;
      @media screen and (min-width: 1024px) {
        text-align: left;
        z-index: 0;
        top: 100%;
        right: 0;
        transform: translate(100%, 0);
      }
    }
    .bgLand {
      position: absolute;
      width: 142.7vw;
      height: 285.2vw;
      left: 0;
      bottom: 0;
      transform: translateY(57%);
      display: none;
      @media all and (min-width: 1024px) {
        display: block;
      }
    }
    .bgSpaceShip {
      position: absolute;
      width: 142.7vw;
      height: 285.2vw;
      left: 0;
      bottom: 0;
      transform: translateY(57%);
      display: none;
      @media all and (min-width: 1024px) {
        display: block;
      }
    }
    .astronaut2 {
      position: absolute;
      left: 0;
      top: 45vh;
      width: 28vw;
      height: 77.77vw;
      display: none;
      @media all and (min-width: 1024px) {
        display: block;
      }
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 2rem 1rem;
    gap: 2rem;
    @media all and (min-width: 1024px) {
      gap: 0;
      padding: 6vw 0.5rem 1rem;
      width: 75vw !important;
    }
    h3 {
      color: #7ddbf9;
      text-align: center;
      text-shadow: 0px 0px 2px #fbbc04;
      margin: 0;
    }
    .swiper {
      width: 100%;
      z-index: 0;
      .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
      }
    }
  }
`;
const BgVidLeft = styled.video`
  height: 100%;
  width: 100%;
  object-fit: fill;
  object-position: 0% 100%;
  opacity: 1;
  visibility: visible;
  @media all and (min-width: 1024px) {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }
`;
const ContCompanies = styled.div`
  position: relative;
  width: 100%;
  opacity: 1;
  visibility: visible;
  @media all and (min-width: 1024px) {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
`;
