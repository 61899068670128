import React, { useState } from "react";
import styled from "styled-components";

const CrewDesktop = ({
  tit1,
  tit2,
  content,
  img,
  setCrewTit1,
  setCrewTit2,
  setCrewContent,
  setCrewImg,
}) => {
  // const [show, setShow] = useState(false);
  const [hover, setHover] = useState(false);
  return (
    <Container
      style={{
        background: `url(${img}) center center / 100% 100%`,
        transform: `${
          hover
            ? `skew(0deg, 0deg) rotateY(0deg)`
            : `skew(0deg, 10deg) rotateY(45deg)`
        }`,
      }}
      onMouseLeave={() => {
        // setShow(false);
        setHover(false);
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
    >
      {/* {show && (
        <div
          style={{
            width: "100%",
          }}
        >
          <div className="crewText">
            <h1>{tit1}</h1>
            <span>({tit2})</span>
            <button data-bs-toggle="modal" data-bs-target="#modalCrew">
              {">"} Read more {"<"}
            </button>
          </div>
        </div>
      )} */}
      <div className="wrapContent">
        <div className="crewText">
          <h4>{tit1}</h4>
          <h6>({tit2})</h6>
          <button
            onClick={() => {
              // setShow(true);
              setCrewTit1(tit1);
              setCrewTit2(tit2);
              setCrewImg(img);
              setCrewContent(content);
            }}
            data-bs-toggle="modal"
            data-bs-target="#modalCrew"
          >
            {">"} Read more {"<"}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default CrewDesktop;
const Container = styled.div`
  height: 15rem;
  width: 12rem;
  margin-left: -6rem;
  margin-right: 0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: 3px solid rgba(251, 188, 4, 0.7);
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(59, 212, 255, 0.6) 0%,
      rgba(59, 212, 255, 0.6) 100%
    );
  }
  .wrapContent {
    position: relative;
    width: 100%;
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    margin-right: 4rem;
    .wrapContent {
      opacity: 1;
      visibility: visible;
    }
    &::before {
      background: linear-gradient(
        0deg,
        rgba(59, 212, 255, 0.2) 0%,
        rgba(59, 212, 255, 0.2) 100%
      );
    }
  }
`;
