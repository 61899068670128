import React from "react";
import styled from "styled-components";
import { BsEmojiSmile } from "react-icons/bs";
import { IoPizzaOutline } from "react-icons/io5";
import { VscFiles } from "react-icons/vsc";
import { IoPeopleOutline } from "react-icons/io5";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const Achievement = ({ percent = "", borderColor }) => {
  return (
    <Container>
      <Swiper
        slidesPerView={1}
        navigation={true}
        breakpoints={{
          540: {
            slidesPerView: 2,
          },
        }}
        modules={[Navigation]}
      >
        <SwiperSlide>
          <GroupItem>
            <Item
              style={{
                borderColor: `${borderColor}`,
              }}
            >
              <div>
                <BsEmojiSmile />
                <div>
                  <h5>250+</h5>
                  <p>Happy customer</p>
                </div>
              </div>
              <span className="badge rounded-pill text-bg-light">
                {percent}
              </span>
            </Item>
            <Item
              style={{
                borderColor: `${borderColor}`,
              }}
            >
              <div>
                <VscFiles />
                <div>
                  <h5>600+</h5>
                  <p>Completed projects</p>
                </div>
              </div>
              <span className="badge rounded-pill text-bg-light">
                {percent}
              </span>
            </Item>
          </GroupItem>
        </SwiperSlide>
        <SwiperSlide>
          <GroupItem>
            <Item
              style={{
                borderColor: `${borderColor}`,
              }}
            >
              <div>
                <IoPizzaOutline />
                <div>
                  <h5>1.8K+</h5>
                  <p>Available Resources</p>
                </div>
              </div>
              <span className="badge rounded-pill text-bg-light">
                {percent}
              </span>
            </Item>
            <Item
              style={{
                borderColor: `${borderColor}`,
              }}
            >
              <div>
                <IoPeopleOutline />
                <div>
                  <h5>11K+</h5>
                  <p>Subscribers</p>
                </div>
              </div>
              <span className="badge rounded-pill text-bg-light">
                {percent}
              </span>
            </Item>
          </GroupItem>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default Achievement;
const Container = styled.div``;
const GroupItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin: 0 0.2rem;
`;
const Item = styled.div`
  border-width: 1px;
  border-style: solid;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  height: 100%;
  > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #7ddbf9;
    > svg {
      width: 1.5rem;
      height: 1.5rem;
    }
    > div {
      width: 60%;
      text-align: left;
      h5 {
        margin: 0;
        font-weight: 700;
      }
      p {
        white-space: nowrap;
        margin: 0;
      }
    }
  }
  span {
    align-self: baseline;
  }
`;
