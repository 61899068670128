import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./sections/Header";
import Footer from "./sections/Footer";
import Home from "./sections/Home";
import OurStory from "./sections/OurStory";
import ExploreTheCosmo from "./sections/ExploreTheCosmo";
import Blog from "./sections/Blog";
import ContactUs from "./sections/ContactUs";
import CosmicConsult from "./sections/CosmicConsult";
import Acknowledgements from "./sections/Acknowledgements";
import Services from "./sections/Services";
import TermsOfServices from "./sections/TermsOfServices";
import PrivacyPolicy from "./sections/PrivacyPolicy";
import Development from "./sections/Development";
import styled from "styled-components";

// import { useSmoothScroll } from "./hooks/useSmoothScroll";

const Main = () =>
  //   {
  //   setMainPage,
  //   handleTurnOffAudio,
  //   handleTurnOnAudio,
  //   setLoadedMain,
  // }
  {
    // useSmoothScroll();
    // sessionStorage.setItem("aiurActive", 1);
    return (
      <Container>
        <Header />
        <Routes>
          <Route path="" element={<Home />} />
          <Route
            path="services"
            element={
              <Services
              // handleTurnOffAudio={handleTurnOffAudio}
              // handleTurnOnAudio={handleTurnOnAudio}
              />
            }
          />
          <Route path="our-story" element={<OurStory />} />
          <Route
            path="explore-the-cosmo"
            element={
              <ExploreTheCosmo
              // handleTurnOffAudio={handleTurnOffAudio}
              // handleTurnOnAudio={handleTurnOnAudio}
              />
            }
          />
          <Route path="blog" element={<Blog />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="cosmic-consult" element={<CosmicConsult />} />
          <Route path="terms-of-services" element={<TermsOfServices />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="development" element={<Development />} />
          <Route
            path="acknowledgements"
            element={
              <Acknowledgements
              // handleTurnOffAudio={handleTurnOffAudio}
              // handleTurnOnAudio={handleTurnOnAudio}
              />
            }
          />
        </Routes>
        <Footer
        // setMainPage={setMainPage}
        // handleTurnOffAudio={handleTurnOffAudio}
        // setLoadedMain={setLoadedMain}
        />
      </Container>
    );
  };

export default Main;

const Container = styled.section`
  width: 100%;
  overflow: hidden;
`;
