import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import { RxChevronLeft, RxChevronRight } from "react-icons/rx";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import { Pagination, EffectCreative } from "swiper/modules";

// gsap
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Blog = () => {
  const handleNextItem = () => {
    let items = document.querySelectorAll("#blogMain .verDesktop .item");
    document
      .querySelector("#blogMain .verDesktop .slide")
      .appendChild(items[0]);
  };
  const handlePrevItem = () => {
    let items = document.querySelectorAll("#blogMain .verDesktop .item");
    document
      .querySelector("#blogMain .verDesktop .slide")
      .prepend(items[items.length - 1]);
  };
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    ScrollTrigger.refresh();
    return () => {
      ScrollTrigger.getAll().forEach((t) => {
        t.scroll(0);
        t.kill(true);
      });
    };
  }, []);
  return (
    <Container id="blogMain">
      <Swiper
        loop={true}
        pagination={{
          dynamicBullets: true,
        }}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[Pagination, EffectCreative]}
        className="verMobile"
      >
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery1_lqy9kz.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery2_hewaas.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery3_lieehd.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery4_jllneu.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery5_wjbx2y.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery6_m8hxwc.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery7_ytn1zs.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery8_wjfgqe.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery9_wlkrrd.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery10_alpo2y.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery11_wrepr3.png"
            alt=""
          />
        </SwiperSlide>
      </Swiper>
      <div className="verDesktop">
        <div className="slide">
          <div
            className="item"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery1_lqy9kz.png")`,
            }}
          ></div>
          <div
            className="item"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery2_hewaas.png")`,
            }}
          ></div>
          <div
            className="item"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery3_lieehd.png")`,
            }}
          ></div>
          <div
            className="item"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery4_jllneu.png")`,
            }}
          ></div>
          <div
            className="item"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery5_wjbx2y.png")`,
            }}
          ></div>
          <div
            className="item"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery6_m8hxwc.png")`,
            }}
          ></div>
          <div
            className="item"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery7_ytn1zs.png")`,
            }}
          ></div>
          <div
            className="item"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery8_wjfgqe.png")`,
            }}
          ></div>
          <div
            className="item"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery9_wlkrrd.png")`,
            }}
          ></div>
          <div
            className="item"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery10_alpo2y.png")`,
            }}
          ></div>
          <div
            className="item"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/blog-gallery11_wrepr3.png")`,
            }}
          ></div>
        </div>
        <button onClick={handlePrevItem}>
          <RxChevronLeft />
        </button>
        <button onClick={handleNextItem}>
          <RxChevronRight />
        </button>
      </div>
    </Container>
  );
};

export default Blog;

const Container = styled.section`
  padding-top: 4rem;
  position: relative;
  background: #1a1a1a;
  @media screen and (min-width: 1024px) {
    padding-top: 3rem;
  }
  .verMobile {
    width: 100%;
    height: 70vw;
    display: block;
    padding-bottom: 1rem;
    .swiper-pagination-bullet-active {
      background: #fbbc04;
    }
    @media screen and (min-width: 1024px) {
      display: none;
    }
    .swiper-slide {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .verDesktop {
    display: none;
    @media screen and (min-width: 1024px) {
      display: block;
      height: calc(100vh - 3rem);
    }
    .slide {
      height: 100%;
      position: relative;
      .item {
        width: 10vw;
        height: 15vw;
        position: absolute;
        bottom: 5%;
        border-radius: 20px;
        box-shadow: 0 30px 50px #505050;
        background-position: 50% 50%;
        background-size: cover;
        display: inline-block;
        transition: 0.5s;
        &:nth-child(1),
        &:nth-child(2) {
          bottom: 0;
          left: 0;
          transform: translate(0, 0);
          border-radius: 0;
          width: 100%;
          height: 100%;
          background-size: 100% 100%;
        }
        &:nth-child(3) {
          left: 75%;
          background-size: cover;
        }
        &:nth-child(4) {
          left: calc(75% + 11vw);
          background-size: cover;
        }
        &:nth-child(n + 5) {
          left: calc(75% + 22vw);
          background-size: cover;
          opacity: 0;
        }
      }
    }
    > button {
      width: 3rem;
      height: 3rem;
      position: absolute;
      bottom: 2rem;
      left: 1rem;
      z-index: 1;
      &:last-of-type {
        left: unset;
        right: 1rem;
      }
      > svg {
        color: #fff;
        width: 100%;
        height: 100%;
      }
    }
  }
`;
