import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import CursorDefault from "../components/CursorDefault";
import CursorPointer from "../components/CursorPointer";
import Companies from "../components/Companies";
import { RxCaretRight } from "react-icons/rx";
import Achievement from "../components/Achievement";
import Title from "../components/Title";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Keyboard } from "swiper/modules";

// gsap
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ModalOurStoryFirst from "../components/ModalOurStoryFirst";
import ModalOurStorySecond from "../components/ModalOurStorySecond";
import ModalOurStoryThird from "../components/ModalOurStoryThird";

const OurStory = () => {
  const [canvasEmpowerTit, setCanvasEmpowerTit] = useState(null);
  const [canvasEmpowerBody, setCanvasEmpowerBody] = useState(null);
  const handleOnClick = (num) => {
    gsap.to(`.drawer-item${num}`, {
      flex: "0 1 25rem",
      cursor: `url(${CursorDefault}), auto`,
      duration: 0,
    });
    if (num === 4) {
      gsap.to(`.drawer-item${num}`, {
        flex: "0 1 20rem",
        duration: 0,
      });
    }
    gsap.to(`.drawer-item${num} .contBefore`, {
      display: "none",
      duration: 0,
    });
    gsap.to(`.drawer-item${num} .contAfter`, {
      display: "block",
      duration: 0,
    });
  };
  const handleOnMouseLeave = () => {
    gsap.to(".drawer-item", {
      flex: "0 1 4rem",
      cursor: `url(${CursorPointer}), auto`,
      duration: 0,
    });
    gsap.to(".drawer-item .contBefore", {
      display: "block",
      duration: 0,
    });
    gsap.to(".drawer-item .contAfter", {
      display: "none",
      duration: 0,
    });
  };
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    ScrollTrigger.refresh();
    return () => {
      ScrollTrigger.getAll().forEach((t) => {
        t.scroll(0);
        t.kill(true);
      });
    };
  }, []);
  return (
    <Container>
      <div
        className="modal fade"
        id="modalAdventA"
        tabIndex="-1"
        aria-labelledby="modalAdventALabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <ContainerModalAdvent>
              <ModalOurStoryFirst />
            </ContainerModalAdvent>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalAdventB"
        tabIndex="-1"
        aria-labelledby="modalAdventBLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <ContainerModalAdvent>
              <ModalOurStorySecond />
            </ContainerModalAdvent>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalAdventC"
        tabIndex="-1"
        aria-labelledby="modalAdventCLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <ContainerModalAdvent>
              <ModalOurStoryThird />
            </ContainerModalAdvent>
          </div>
        </div>
      </div>
      <Empower>
        <h2>To empower businesses with exceptional marketing services</h2>
        <div className="empowerBottom">
          <h5>
            In the Beginning: The Founding of Aiur Marketing
            <br />
            In a digital universe brimming with potential, Aiur Marketing
            emerged as a beacon of innovation and strategy. Just as the ancient
            and wise Protoss of Aiur harnessed the power of the Khala to unite
            their minds, our founders envisioned a marketing realm where
            creativity and technology would converge into a formidable force.
          </h5>
          <div className="drawerGroup">
            <DrawerItem
              className="drawer-item drawer-item1"
              onClick={() => {
                handleOnClick(1);
              }}
              onMouseLeave={() => {
                handleOnMouseLeave();
              }}
            >
              <h6 className="contBefore">THE ADVENTURERS</h6>
              <h6 className="contAfter">THE ADVENTURERS</h6>
              <p className="contAfter">
                In the boundless digital universe, the Adventurers of Aiur
                Marketing forge their path with tenacity and vision. From the
                bustling metropolises of social media to the unexplored
                frontiers of the web, these brave souls claim their place in the
                vast network. With each campaign launched and every strategy
                devised, they earn the right to be known not just as clients,
                but as true Navigators of the Digital Expanse.
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#modalAdventA"
                  className="contAfter"
                >
                  <RxCaretRight />
                  Read more
                </button>
              </p>
            </DrawerItem>
            <DrawerItem
              className="drawer-item drawer-item2"
              onClick={() => {
                handleOnClick(2);
              }}
              onMouseLeave={() => {
                handleOnMouseLeave();
              }}
            >
              <h6 className="contBefore">TDMA</h6>
              <h6 className="contAfter">The Digital Marketing Architects</h6>
              <p className="contAfter">
                Meet the skilled architects of Aiur Marketing, an elite team of
                digital marketing strategists and SEO specialists. These
                dedicated professionals are the driving force behind innovative
                online campaigns that engage and convert. As experts in content
                marketing, social media trends, and Google analytics, they craft
                data-driven strategies that elevate brands to the top of search
                results. Only the most adept become the revered architects of
                Aiur - leading your business to digital triumph in the
                competitive online marketplace.
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#modalAdventB"
                  className="contAfter"
                >
                  <RxCaretRight />
                  Read more
                </button>
              </p>
            </DrawerItem>
            <DrawerItem
              className="drawer-item drawer-item3"
              onClick={() => {
                handleOnClick(3);
              }}
              onMouseLeave={() => {
                handleOnMouseLeave();
              }}
            >
              <h6 className="contBefore">THE YEAR 2024</h6>
              <h6 className="contAfter">THE YEAR 2024</h6>
              <p className="contAfter">
                As the digital dawn of 2024 broke, Aiur Marketing's Adventurers
                stood poised at the frontier of a transformed cyberspace. In
                this year of innovation, algorithms became ancient scripts, and
                artificial intelligence the new compass. The Workers, custodians
                of this digital odyssey, charted pathways through the uncharted
                techno-scape, turning visions into conquests within the cosmic
                expanse of marketing's future.
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#modalAdventC"
                  className="contAfter"
                >
                  <RxCaretRight />
                  Read more
                </button>
              </p>
            </DrawerItem>
          </div>
          <GrBtnOffCanvas>
            <ul className="list-group list-group-flush">
              <li className="list-group-item list-group-item-warning bg-transparent text-center">
                <button
                  onClick={() => {
                    setCanvasEmpowerTit("THE ADVENTURERS");
                    setCanvasEmpowerBody(<ModalOurStoryFirst />);
                  }}
                  className="btn btn-outline-warning"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasEmpower"
                  aria-controls="offcanvasEmpower"
                >
                  THE ADVENTURERS
                </button>
              </li>
              <li className="list-group-item list-group-item-warning bg-transparent text-center">
                <button
                  onClick={() => {
                    setCanvasEmpowerTit("The Digital Marketing Architects");
                    setCanvasEmpowerBody(<ModalOurStorySecond />);
                  }}
                  className="btn btn-outline-warning"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasEmpower"
                  aria-controls="offcanvasEmpower"
                >
                  TDMA
                </button>
              </li>
              <li className="list-group-item list-group-item-warning bg-transparent text-center">
                <button
                  onClick={() => {
                    setCanvasEmpowerTit("THE YEAR 2024");
                    setCanvasEmpowerBody(<ModalOurStoryThird />);
                  }}
                  className="btn btn-outline-warning"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasEmpower"
                  aria-controls="offcanvasEmpower"
                >
                  THE YEAR 2024
                </button>
              </li>
            </ul>
            <div
              className="offcanvas offcanvas-bottom"
              tabIndex="-1"
              id="offcanvasEmpower"
            >
              <div className="offcanvas-header">
                <h6 className="offcanvas-title">{canvasEmpowerTit}</h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body bg-black">{canvasEmpowerBody}</div>
            </div>
          </GrBtnOffCanvas>
        </div>
      </Empower>
      <Knowledge>
        <TitleTeam>
          <Title h2span="UNIVER'S" h2="KNOWLEDGE" color="#FBBC04" />
        </TitleTeam>
        <div className="content">
          <DrawerItem className="drawer-item drawer-small">
            <h6>
              The First Warp Jump:
              <br />
              Breaking Boundaries
            </h6>
            <p>
              Our initial campaigns were like the first warp jump of a Protoss
              Carrier, daring and revolutionary. We ventured into uncharted
              territories of digital marketing, wielding the power of SEO like
              the Psi Blades of a Zealot, cutting through the noise and clutter
              to position our clients at the forefront of their industries.
            </p>
          </DrawerItem>
          <DrawerItem
            className="drawer-item drawer-item4"
            onClick={() => {
              handleOnClick(4);
            }}
            onMouseLeave={() => {
              handleOnMouseLeave();
            }}
          >
            <h6 className="contBefore">Breaking Boundaries</h6>
            <h6 className="contAfter">
              The First Warp Jump:
              <br />
              Breaking Boundaries
            </h6>
            <p className="contAfter">
              Our initial campaigns were like the first warp jump of a Protoss
              Carrier, daring and revolutionary. We ventured into uncharted
              territories of digital marketing, wielding the power of SEO like
              the Psi Blades of a Zealot, cutting through the noise and clutter
              to position our clients at the forefront of their industries.
            </p>
          </DrawerItem>
        </div>
      </Knowledge>
      <Chronicles>
        <video
          width="100%"
          height="130%"
          loop
          muted
          autoPlay
          style={{
            objectFit: "cover",
            position: "absolute",
            top: 0,
          }}
        >
          <source
            src="https://res.cloudinary.com/db85dpvaz/video/upload/aiur-marketing/ourStory-welcome_qkdy7s.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="content">
          <h2>The Chronicles of Aiur: Numbers That Narrate Our Saga</h2>
          <Achievement percent="+2,5%" borderColor="#FBBC04" />
          <h6>
            As keepers of digital wisdom, we've cultivated a repository of
            expertise that guides businesses through the evolving landscape of
            technology and marketing. Our strategies are not mere conjectures
            but are drawn from the deep well of our collective experiences and
            the success stories we've written with our clients.
          </h6>
        </div>
      </Chronicles>
      <Companies />
      <TitleTeam>
        <Title h2span="THE CREATION" h2="TEAM" color="#FBBC04" />
      </TitleTeam>
      <Team>
        <Swiper
          navigation={true}
          spaceBetween={0}
          keyboard={{
            enabled: true,
          }}
          slidesPerView={1}
          breakpoints={{
            464: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
          }}
          modules={[Keyboard, Navigation]}
        >
          <SwiperSlide>
            <div className="title">
              <h5>ALEXIS NGUYEN</h5>
              <h6>Sale Consultant</h6>
            </div>
            <img
              src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/ourstory-team1_inuaze.png"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <div className="title">
              <h5>THOMAS NGUYEN</h5>
              <h6>BA/Project Management</h6>
            </div>
            <img
              src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/ourstory-team2_zfn0kc.png"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <div className="title">
              <h5>GIANG NGUYEN</h5>
              <h6>UI/UX Designer</h6>
            </div>
            <img
              src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/ourstory-team3_wlbju3.png"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <div className="title">
              <h5>HUY NGUYEN VAN</h5>
              <h6>Full-stack Developers</h6>
            </div>
            <img
              src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/ourstory-team4_iseh5e.png"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <div className="title">
              <h5>FINN NGUYEN</h5>
              <h6>Front-end Developer</h6>
            </div>
            <img
              src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/ourstory-team5_y0lwxq.png"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <div className="title">
              <h5>KHANH NGUYEN</h5>
              <h6>Full-stack Developers</h6>
            </div>
            <img
              src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/ourstory-team6_ortzgw.png"
              alt=""
            />
          </SwiperSlide>
        </Swiper>
      </Team>
    </Container>
  );
};

export default OurStory;

const Container = styled.section`
  background: #00253a;
  position: relative;
  padding-bottom: 2rem;
`;
const ContainerModalAdvent = styled.div`
  color: white;
  background: #000;
  position: relative;
  padding: 2rem;
  letter-spacing: 0.5px;
  max-width: 40rem;
  border: 2px solid #fbbc04;
  border-radius: 2rem 2rem 2rem 0;
`;
const Empower = styled.div`
  position: relative;
  height: auto;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/ourstory-welcome-bg_ypd2vr.png")
      center / cover;
  padding: 5rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 0;
  @media screen and (min-width: 1024px) {
    padding: 2rem;
    margin-top: 3rem;
  }
  > h2 {
    color: #7ddbf9;
    text-align: center;
    text-shadow: 0px 0px 1px #fbbc04;
    font-family: "Turret Road";
    font-weight: bold;
    margin-bottom: 1rem;
    @media screen and (min-width: 1024px) {
      margin-bottom: 3rem;
    }
  }
  .empowerBottom {
    position: relative;
    > h5 {
      position: relative;
      color: #fff;
      font-family: "Turret Road";
      width: 100%;
      text-align: justify;
      margin: 0;
      @media screen and (min-width: 1024px) {
        width: 60%;
        position: absolute;
        bottom: 50%;
        transform: translate(0, 50%);
      }
    }
    > .drawerGroup {
      display: none;
      @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: flex-end;
        gap: 0.2rem;
        position: relative;
      }
    }
  }
`;
const DrawerItem = styled.div`
  background: rgba(0, 0, 0, 0.4);
  color: rgba(251, 188, 4, 0.7);
  position: relative;
  display: flex;
  height: 20rem;
  flex: 0 1 4rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: url(${CursorPointer}), auto;
  overflow: hidden;
  border: 1px solid #fbbc04;
  border-radius: 1rem 1rem 0 1rem;
  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fbbc04;
  }
  > h6 {
    text-align: center;
    white-space: nowrap;
    margin: 0;
    &:first-of-type {
      transform: rotate(-90deg);
    }
  }
  > p {
    font-size: 0.95rem;
    position: relative;
    color: #fff;
    padding: 0 1rem;
    margin: 0;
    > button {
      position: relative;
      color: #fff;
      text-shadow: 0px 0px 2px #fbbc04;
      font-size: 0.95rem;
      align-self: flex-start;
      > svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .contAfter {
    display: none;
  }
  &.drawer-small {
    display: flex;
    flex: 0 1 20rem;
    color: #fbbc04;
    background: rgba(0, 0, 0, 0.8);
    > h6 {
      transform: rotate(0deg);
    }
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
  &.drawer-item4 {
    display: none;
    @media screen and (min-width: 1024px) {
      display: flex;
    }
  }
`;
const Knowledge = styled.div`
  padding: 0;
  @media screen and (min-width: 1024px) {
    padding: 0 10rem;
  }
  > .content {
    display: flex;
    background: url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/ourstory-knowledge-bg_xkoct1.jpg)
      center 60% / cover;
    clip-path: polygon(2rem 0, 100% 0, 100% 100%, 0 100%, 0 2rem);
    padding: 3rem 1rem;
  }
  .drawer-item {
    height: 17rem;
    border-radius: 1rem 1rem 1rem 0;
  }
`;
const Chronicles = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 3rem;
  .content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: #7ddbf9;
    padding: 2rem 1rem;
    @media screen and (min-width: 1024px) {
      padding: 2rem;
    }
    > h2 {
      text-align: center;
      font-family: Turret Road;
      font-weight: bold;
      letter-spacing: 1px;
      margin: 0;
    }
    > h6 {
      text-align: justify;
      text-shadow: 0px 0px 2px #fbbc04;
      line-height: 1.4;
      margin: 0;
    }
  }
`;
const Team = styled.div`
  > .swiper {
    border: 6px solid #ee810b;
    border-left: none;
    border-right: none;
    .swiper-slide {
      height: 100%;
      background: #fbbc04;
      clip-path: polygon(
        5% 0,
        78% 0%,
        90% 60%,
        80% 100%,
        10% 100%,
        20% 60%,
        20% 60%
      );
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      user-select: none;
      .title {
        margin-top: 0.4em;
        color: #161751;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        line-height: 1;
        h5 {
          transform: translateX(-10%);
          font-weight: 600;
          white-space: nowrap;
          margin: 0;
        }
        h6 {
          text-align: right;
          white-space: nowrap;
          margin: 0;
        }
      }
      img {
        width: 7rem;
      }
    }
  }
`;
const GrBtnOffCanvas = styled.div`
  display: block;
  margin-top: 1rem;
  @media screen and (min-width: 1024px) {
    display: none;
  }
  > .list-group {
    button {
      background: rgba(0, 0, 0, 0.85);
    }
  }
`;
const TitleTeam = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-left: 0;
  @media screen and (min-width: 540px) {
    justify-content: flex-start;
    margin-left: 2rem;
  }
`;
