import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { RxChevronRight } from "react-icons/rx";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

// gsap
import gsap from "gsap";

const MediaContent = ({ forwardedRef }) => {
  const hdlHoverMediaLeft = () => {
    let mm = gsap.matchMedia();
    mm.add(
      {
        isMobile: "(max-width: 767px)",
        isTablet: "(min-width: 768px) and (max-width: 1023px)",
        isDesktop: "(min-width: 1024px)",
      },
      (context) => {
        let { isDesktop } = context.conditions;
        if (isDesktop) {
          gsap.to(".mySwipermedia .swiper-slide:first-of-type", {
            flex: "1 1 60%",
            backgroundSize: "100%",
          });
          gsap.to(".mySwipermedia .swiper-slide:last-of-type", {
            backgroundSize: "150% 120%",
          });
          gsap.to(
            ".mySwipermedia .swiper-slide:first-of-type .txtHide",
            {
              autoAlpha: 1,
            },
            "<"
          );
          gsap.to(
            ".mySwipermedia .swiper-slide:last-of-type .txtHide",
            {
              autoAlpha: 0,
            },
            "<"
          );
        }
      }
    );
  };
  const hdlHoverMediaRight = () => {
    let mm = gsap.matchMedia();
    mm.add(
      {
        isMobile: "(max-width: 767px)",
        isTablet: "(min-width: 768px) and (max-width: 1023px)",
        isDesktop: "(min-width: 1024px)",
      },
      (context) => {
        let { isDesktop } = context.conditions;
        if (isDesktop) {
          gsap.to(".mySwipermedia .swiper-slide:first-of-type", {
            flex: "1 1 40%",
            backgroundSize: "170%",
          });
          gsap.to(".mySwipermedia .swiper-slide:last-of-type", {
            backgroundSize: "100% 100%",
          });
          gsap.to(
            ".mySwipermedia .swiper-slide:first-of-type .txtHide",
            {
              autoAlpha: 0,
            },
            "<"
          );
          gsap.to(
            ".mySwipermedia .swiper-slide:last-of-type .txtHide",
            {
              autoAlpha: 1,
            },
            "<"
          );
        }
      }
    );
  };
  return (
    <Container ref={forwardedRef}>
      <Swiper
        navigation={true}
        spaceBetween={0}
        slidesPerView={1}
        breakpoints={{
          464: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        }}
        modules={[Navigation]}
        className="mySwipermedia"
      >
        <SwiperSlide onMouseEnter={hdlHoverMediaLeft}>
          <div>
            <h4>
              THE
              <br />
              <span>GAMES</span>
              <br />
              OF FATE
            </h4>
            <p className="txtHide">
              Versatile utility ship with
              <br />
              combat capabilities.
            </p>
          </div>
          <HashLink to="/explore-the-cosmo#thegame" className="txtHide h5">
            BRING IT ON
            <RxChevronRight />
          </HashLink>
        </SwiperSlide>
        <SwiperSlide onMouseEnter={hdlHoverMediaRight}>
          <div>
            <h4>
              THE
              <br />
              <span>VIDEOS</span>
              <br />
              GALLERY
            </h4>
            <p className="txtHide">
              Footages that was rediscovered at the cost of a great number of
              lives.
            </p>
          </div>
          <Link to="acknowledgements" className="txtHide h5">
            LET'S PLAY
            <RxChevronRight />
          </Link>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default MediaContent;

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  @media screen and (min-width: 1024px) {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 0 7rem;
  }
  .swiper {
    clip-path: polygon(
      2rem 0,
      100% 0,
      100% calc(100% - 2rem),
      calc(100% - 2rem) 100%,
      0 100%,
      0 2rem
    );
    width: 100%;
    height: 60vh;
    .swiper-slide {
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      > div {
        h4 {
          color: #7ddbf9;
          font-family: Inter;
          margin: 0;
          span {
            font-weight: bold;
          }
        }
        p {
          color: #7ddbf9;
          font-family: Inter;
          letter-spacing: 0.5px;
          margin: 0;
        }
      }
      a {
        color: #7ddbf9;
        text-align: right;
        font-family: Inter;
        display: flex;
        align-items: center;
        > svg {
          width: 2rem;
          height: 2rem;
        }
      }
      &:first-of-type {
        background-image: url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-media1-bg_v6w4il.jpg);
        background-position: center bottom 35%;
        background-size: cover;
        @media all and (min-width: 1024px) {
          background-size: 170%;
          flex: 1 1 40%;
          .txtHide {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
      &:last-of-type {
        background-image: url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/home-media2-bg_okyu4w.jpg);
        background-position: left top;
        background-size: cover;
        align-items: flex-end;
        .txtHide {
          color: #fff !important;
        }
        @media all and (min-width: 1024px) {
          background-size: 100% 100%;
          flex: 1 1 auto;
          .txtHide {
            opacity: 1;
            visibility: visible;
            max-width: 80%;
            margin-left: auto;
            max-height: 2.8rem;
          }
        }
        h4 {
          color: #fff !important;
          text-align: right;
        }
      }
    }
  }
`;
