import React from "react";
import { MdMailOutline } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";
import styled from "styled-components";

const ContactInfor = ({
  position = "",
  bg,
  color1 = "#cfdee8",
  color2 = "#ddd9d5",
}) => {
  return (
    <Container
      style={{
        position: position,
        background: bg,
      }}
    >
      <h1
        style={{
          color: color1,
        }}
      >
        Contact Infomation
      </h1>
      <ul
        className="ps-0"
        style={{
          color: color2,
        }}
      >
        <Item className="align-items-center">
          <MdMailOutline />
          <span>Support@aiurmarketing.com</span>
        </Item>
        <Item className="align-items-center">
          <FiPhone />
          <span>(909)-632-1643</span>
        </Item>
        <Item>
          <GrLocation />
          <span>
            1861 Mountain View Ave,
            <br />
            Loma Linda CA 92354
          </span>
        </Item>
      </ul>
    </Container>
  );
};

export default ContactInfor;

const Container = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: 3rem auto 0;
  clip-path: polygon(
    1rem 0,
    calc(100% - 1rem) 0,
    100% 1rem,
    100% calc(100% - 1rem),
    calc(100% - 1rem) 100%,
    0 100%,
    0 1rem
  );
  padding: 1rem 0.5rem 2rem;
  @media screen and (min-width: 1024px) {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  h1 {
    font-family: "Turret Road";
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
  }
`;
const Item = styled.li`
  font-family: "Turret Road";
  font-size: 0.7rem;
  letter-spacing: 1px;
  text-align: left;
  display: flex;
  gap: 0.3rem;
  margin: 1rem 0;
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;
