import React from "react";
import styled from "styled-components";

const TitleService = ({ icon, title }) => {
  return (
    <Container>
      {/* <img src={icon} alt="" /> */}
      {icon}
      <p>{title}</p>
    </Container>
  );
};

export default TitleService;

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  color: #fbbc04;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  p {
    margin: 0;
  }
`;
