// import { useState } from "react";
import Main from "./Main";
import GlobalStyles from "./styles/GlobalStyles.js";
// import Intro from "./Intro";
// import BarAudio from "./components/BarAudio";

function App() {
  // const [mainPage, setMainPage] = useState(false);
  // let aiurActive = sessionStorage.getItem("aiurActive");
  // const [LoadedMain, setLoadedMain] = useState(false);

  // const audioRef = useRef(null);
  // const flagAudioRef = useRef(0);
  // const handleSwitchAudio = () => {
  //   if (audioRef.current.paused) {
  //     audioRef.current.play();
  //     document.getElementById("bar-1").classList.add("Anim");
  //     document.getElementById("bar-2").classList.add("Anim");
  //     document.getElementById("bar-3").classList.add("Anim");
  //     document.getElementById("bar-4").classList.add("Anim");
  //     document.getElementById("bar-5").classList.add("Anim");
  //     document.getElementById("bar-6").classList.add("Anim");
  //   } else {
  //     audioRef.current.pause();
  //     document.getElementById("bar-1").classList.remove("Anim");
  //     document.getElementById("bar-2").classList.remove("Anim");
  //     document.getElementById("bar-3").classList.remove("Anim");
  //     document.getElementById("bar-4").classList.remove("Anim");
  //     document.getElementById("bar-5").classList.remove("Anim");
  //     document.getElementById("bar-6").classList.remove("Anim");
  //   }
  // };
  // const handleTurnOnAudio = () => {
  //   if (flagAudioRef.current === 1) {
  //     audioRef.current.play();
  //     document.getElementById("bar-1").classList.add("Anim");
  //     document.getElementById("bar-2").classList.add("Anim");
  //     document.getElementById("bar-3").classList.add("Anim");
  //     document.getElementById("bar-4").classList.add("Anim");
  //     document.getElementById("bar-5").classList.add("Anim");
  //     document.getElementById("bar-6").classList.add("Anim");
  //   }
  //   flagAudioRef.current = 0;
  // };
  // const handleTurnOffAudio = () => {
  //   if (audioRef.current.paused === false) {
  //     audioRef.current.pause();
  //     document.getElementById("bar-1").classList.remove("Anim");
  //     document.getElementById("bar-2").classList.remove("Anim");
  //     document.getElementById("bar-3").classList.remove("Anim");
  //     document.getElementById("bar-4").classList.remove("Anim");
  //     document.getElementById("bar-5").classList.remove("Anim");
  //     document.getElementById("bar-6").classList.remove("Anim");
  //     flagAudioRef.current = 1;
  //   }
  // };
  return (
    <>
      <GlobalStyles />
      {/* <audio
        loop
        ref={audioRef}
        src="https://res.cloudinary.com/db85dpvaz/video/upload/aiur-marketing/soundtrack_mg2jy1.mp3"
      /> */}
      {/* {aiurActive || mainPage ? (
        <Main
          setMainPage={setMainPage}
          handleTurnOffAudio={handleTurnOffAudio}
          handleTurnOnAudio={handleTurnOnAudio}
          setLoadedMain={setLoadedMain}
        />
      ) : (
        <Intro
          setMainPage={setMainPage}
          handleSwitchAudio={handleSwitchAudio}
          LoadedMain={LoadedMain}
        />
      )} */}
      <Main
      // setMainPage={setMainPage}
      // handleTurnOffAudio={handleTurnOffAudio}
      // handleTurnOnAudio={handleTurnOnAudio}
      // setLoadedMain={setLoadedMain}
      />
      {/* <BarAudio handleSwitchAudio={handleSwitchAudio} /> */}
    </>
  );
}

export default App;
