import React, { useLayoutEffect } from "react";
// gsap
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";

const PrivacyPolicy = () => {
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    ScrollTrigger.refresh();
    return () => {
      ScrollTrigger.getAll().forEach((t) => {
        t.scroll(0);
        t.kill(true);
      });
    };
  }, []);
  return (
    <Container>
      <div className="title">
        <h2 className="fw-bold m-0">
          AIUR MARKETING
          <br />
          <small className="fw-light">PRIVACY POLICY</small>
        </h2>
      </div>
      <div className="content">
        <p>
          Welcome to Aiur Marketing
          <br />
          In the expansive universe of Aiur, where the past, present, and future
          converge, Aiur Marketing stands as a beacon of innovation and
          tradition. As guardians of your digital journey, we prioritize not
          only the advancement of marketing strategies but also the safeguarding
          of your personal and non-personal information. This Privacy Policy
          serves as our commitment to protect and respect the privacy rights of
          our citizens—users like you who seek to explore, connect, and grow
          within the Aiur ecosystem.
          <br />
          Acceptance of Privacy Policy
          <br />
          By accessing Aiur Marketing's services, including our website, digital
          platforms, and any content or services provided therein, you
          acknowledge and agree to the practices described in this Privacy
          Policy. This agreement is paramount to the trust and safety of our
          community. Should we update this policy, we pledge to provide you with
          timely notice, ensuring your continued consent.
        </p>
        <ul>
          <li>
            The Guardians of Your Information
            <p>
              Aiur Marketing is the custodian of your personal information,
              adhering to the highest standards of privacy and data protection.
              Our headquarters, nestled within the heart of the digital cosmos,
              serve as the command center from which we oversee the collection,
              processing, and storage of your data.
            </p>
          </li>
          <li>
            The Essence of Your Digital Footprint
            <p>
              In the realm of Aiur, every interaction is a thread in the fabric
              of our universe. Personal Data, akin to the unique essence of
              every being, includes identifiable information such as names,
              digital identifiers, and more. Non-Personal Data, on the other
              hand, comprises information that cannot trace back to you,
              ensuring your anonymity as you traverse our digital expanse.
            </p>
          </li>
          <li>
            The Journey of Data Collection
            <p>
              <span>At the Threshold of Aiur Marketing</span>
              Upon your arrival at our digital domain, we are greeted with data
              that helps us enhance your voyage. This includes but is not
              limited to your device's signature, the celestial pathways you
              navigate (Referral URLs), and the timestamps of your explorations.
              This information is vital for optimizing your experience and
              ensuring the smooth operation of our services.
              <span>The Rite of Passage: Account Registration</span>
              To fully partake in the Aiur experience, registering an account is
              a rite of passage. This process entails sharing specific personal
              information, which we vow to protect with the utmost diligence.
              Your digital alias, contact details, and interstellar identifiers
              are collected to not only secure but also personalize your
              journey.
              <span>Engaging in the Marketplace of Ideas</span>
              The exchange of goods, ideas, and services is the lifeblood of
              Aiur's economy. When you engage in such transactions, additional
              personal and non-personal information is gathered to fulfill our
              contractual obligations and ensure a seamless trade. This includes
              transactional details, objects of exchange, and the chronicles of
              your contributions to Aiur's prosperity.
              <span>The Arena of Challenge and Triumph</span>
              In the heart of Aiur lies the arena, where competitions and
              collaborative endeavors unfold. Participation requires the
              collection of performance data, achievements, and your chosen
              banners of identity. This data not only celebrates your victories
              but also contributes to the communal tapestry of Aiur.
              <span>Guardianship and Vigilance</span>
              Aiur Marketing stands vigilant against misuse and discord within
              our domain. Should the harmony of Aiur be threatened, we are
              prepared to collect additional data to safeguard our community and
              uphold the principles of our civilization.
            </p>
          </li>
          <li>
            The Circuits of Consent: Data Sharing and Processing
            <p>
              In the vast network of Aiur, connections form the backbone of our
              community. Your data, a beacon of your identity and preferences,
              is only shared under the banner of enhancing your experience and
              providing the services you've embarked upon. With affiliated
              entities and third-party guardians who share our commitment to
              privacy, we ensure that your data is treated with respect and used
              solely for the purposes for which it was collected. Our alliances
              with these entities are forged in transparency, safeguarding your
              journey through Aiur.
            </p>
          </li>
          <li>
            The Sanctum of Security: Safeguarding Your Data
            <p>
              Within the digital realm of Aiur, security is not just a protocol;
              it's a promise. We employ advanced cryptographic shields and
              protocols to protect your data from cosmic threats. These measures
              are continuously evaluated and updated to combat emerging dangers.
              Despite the vastness of the digital cosmos, where shadowy threats
              lurk, we strive to be vigilant sentinels, protecting the sanctity
              of your information.
            </p>
          </li>
          <li>
            The Crossroads of Choice: Your Rights and Controls
            <p>
              As a citizen of Aiur, autonomy over your digital essence is
              paramount. You wield the power to access, correct, and delete your
              data within our archives. Should you choose to alter the path of
              your data's journey, we provide the tools and guidance to ensure
              that your wishes are honored. This empowerment is a cornerstone of
              our civilization, reflecting the respect and value we hold for
              each member of our community.
            </p>
          </li>
          <li>
            The Vortex of Change: Amendments to the Privacy Policy
            <p>
              As Aiur evolves, so too must our policies that safeguard its
              citizens. We reserve the right to modify this Privacy Policy to
              reflect new services, regulatory changes, or advances in privacy
              protection. Changes will be communicated through the cosmic web of
              Aiur, ensuring you are informed and can respond to these
              evolutions. Your continued engagement with our services after such
              modifications will serve as your acceptance of our updated
              practices.
            </p>
          </li>
          <li>
            The Harmony of Data Transfer
            <p>
              The universe of Aiur is vast, and so the data that traverses it
              may cross through various sectors and realms beyond our own. We
              are committed to ensuring that your data is protected with the
              same vigor, irrespective of where it flows within the cosmos.
              Transfers of data are shielded with protocols and agreements that
              uphold the sanctity of your information, no matter the star system
              it finds itself in.
            </p>
          </li>
          <li>
            The Guardians of Minors: Protecting Our Young
            <p>
              Aiur, in its wisdom, recognizes the need to offer special
              protection to its youngest explorers. Our services are not
              directed towards individuals under the age of digital consent. We
              do not knowingly collect data from these young adventurers.
              Guardians of minors who believe their ward has traversed our realm
              and left traces of their journey are encouraged to contact us,
              ensuring their safe passage and the protection of their digital
              footprints.
            </p>
          </li>
          <li>
            The Path to Enlightenment: Exercising Your Rights
            <p>
              Within Aiur, every citizen is empowered to understand, access, and
              control their data. Whether you seek to review, correct, or erase
              your digital essence from our archives, we provide the means for
              you to exercise these rights. Our dedicated Council of Data
              Stewards is at your service, guiding you through the process and
              ensuring your requests are honored in accordance with the laws of
              the cosmos.
            </p>
          </li>
          <li>
            The Beacon of Contact: Reaching Out to Aiur Marketing
            <p>
              Should you have inquiries, concerns, or words of wisdom to impart
              regarding our use of your data, the Beacon of Contact shines light
              on the path to our Council. Reach out through the provided
              channels, and you shall find a willing ear and a helpful guide.
              Our commitment to transparency and dialogue is unwavering, as we
              believe it strengthens the bonds of our community.
            </p>
          </li>
          <li>
            Conclusion: The Covenant of Privacy
            <p>
              This Privacy Policy is a testament to Aiur Marketing's dedication
              to the principles of privacy, respect, and community. It serves as
              a covenant between Aiur Marketing and its citizens, outlining our
              mutual responsibilities and rights in the shared digital universe
              we inhabit. We pledge to guard your data as we would our most
              sacred relics, ensuring that the legacy of Aiur's digital realm
              remains secure, vibrant, and welcoming to all who seek its
              knowledge and community.
            </p>
          </li>
        </ul>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;

const Container = styled.section`
  background: #00253a;
  padding: 5rem 1rem 2rem;
  @media screen and (min-width: 1024px) {
    padding: 5rem 2rem 2rem;
  }
  .title {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 65px #0da8ff;
    font-family: "Turret Road";
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }
  .content {
    color: #7ddbf9;
    line-height: 1.6;
    ul {
      list-style: upper-roman;
      color: #7ddbf9;
      li {
        text-shadow: 0px 0px 60px rgba(255, 255, 255, 0.4);
        font-family: "Turret Road";
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
    p {
      font-family: Inter;
      font-size: 1rem;
      text-align: justify;
      font-weight: lighter;
      counter-reset: my-sec-counter;
      span {
        display: block;
        &::before {
          counter-increment: my-sec-counter 1;
          content: "3." counter(my-sec-counter) ". ";
        }
      }
    }
  }
`;
