import React from "react";
import styled from "styled-components";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cube";

import { Pagination, Navigation, EffectCube } from "swiper/modules";

const Companies = () => {
  return (
    <Container>
      <Swiper
        loop={true}
        effect={"cube"}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        pagination={true}
        modules={[EffectCube, Pagination]}
        className="mySwiperMobile"
      >
        <SwiperSlide>
          <h5>
            WORLDCRAFT
            <br />
            LOGISTICS
          </h5>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/companies-logo1_xqplyb.png"
            alt=""
            style={{ width: "65%" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <h5>
            ACEXUS
            <br />
            TRANSPORTATION
          </h5>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/companies-logo2_m4cwn6.png"
            alt=""
            style={{ width: "55%" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <h5>HONG DUC 1</h5>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/companies-logo3_job5ao.png"
            alt=""
            style={{ width: "43%" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <h5>
            LIVE WELL
            <br />
            THERAPEUTICS
          </h5>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/companies-logo4_kfl1nn.png"
            alt=""
            style={{ width: "70%" }}
          />
        </SwiperSlide>
      </Swiper>
      <Swiper
        navigation={true}
        slidesPerView={4}
        modules={[Navigation]}
        className="mySwiperDesktop"
      >
        <SwiperSlide>
          <h5>
            WORLDCRAFT
            <br />
            LOGISTICS
          </h5>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/companies-logo1_xqplyb.png"
            alt=""
            style={{ width: "65%" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <h5>
            ACEXUS
            <br />
            TRANSPORTATION
          </h5>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/companies-logo2_m4cwn6.png"
            alt=""
            style={{ width: "55%" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <h5>HONG DUC 1</h5>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/companies-logo3_job5ao.png"
            alt=""
            style={{ width: "43%" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <h5>
            LIVE WELL
            <br />
            THERAPEUTICS
          </h5>
          <img
            src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/companies-logo4_kfl1nn.png"
            alt=""
            style={{ width: "70%" }}
          />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default Companies;

const Container = styled.div`
  display: flex;
  position: relative;
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    @media screen and (min-width: 1024px) {
      background: linear-gradient(
          0deg,
          rgba(211, 211, 211, 0.2) 0%,
          rgba(211, 211, 211, 0.2) 100%
        ),
        url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/companies-bg_aedsbr.jpg)
          center / cover;
    }
    opacity: 0.3;
  }
  .mySwiperMobile {
    width: 16rem;
    display: flex;
    padding-bottom: 1.8rem;
    .swiper-pagination-bullet-active {
      background: #fbbc04;
    }
    .swiper-slide {
      background: linear-gradient(
          0deg,
          rgba(26, 33, 43, 0.8) 0%,
          rgba(26, 33, 43, 0.8) 100%
        ),
        url(https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/companies-bg_aedsbr.jpg)
          center / cover;
    }
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
  .mySwiperDesktop {
    width: 100%;
    display: none;
    @media screen and (min-width: 1024px) {
      display: flex;
    }
  }
  .swiper-slide {
    padding: 1rem 0;
    border: 1px solid #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    h5 {
      color: #f2f4f8;
      font-family: Inter;
      line-height: 1.3;
      letter-spacing: 0.62px;
      margin: 0;
      text-align: center;
    }
  }
`;
