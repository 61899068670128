import React, { useLayoutEffect } from "react";
// gsap
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";
import CursorDefault from "../components/CursorDefault.jsx";
import CursorPointer from "../components/CursorPointer";

const CosmicConsult = () => {
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    ScrollTrigger.refresh();
    return () => {
      ScrollTrigger.getAll().forEach((t) => {
        t.scroll(0);
        t.kill(true);
      });
    };
  }, []);
  return (
    <Container>
      <div className="block">
        <video
          width="100%"
          height="100%"
          loop
          muted
          autoPlay
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            objectFit: "cover",
          }}
        >
          <source
            src="https://res.cloudinary.com/db85dpvaz/video/upload/aiur-marketing/consult-welcome_hueuax.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <form>
          <div className="divLeft">
            <div className="colItem">
              <label htmlFor="formControlInputName" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="formControlInputName"
                placeholder="John Carter"
              />
            </div>
            <div className="colItem">
              <label htmlFor="formControlInputPhone" className="form-label">
                Phone
              </label>
              <input
                type="tel"
                className="form-control"
                id="formControlInputPhone"
                placeholder="(123) 456 - 789"
              />
            </div>
            <div className="colItem">
              <label htmlFor="formControlInputEmail" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="formControlInputEmail"
                placeholder="example@email.com"
              />
            </div>
            <div className="colItem">
              <label htmlFor="formControlInputCompany" className="form-label">
                Company
              </label>
              <input
                type="text"
                className="form-control"
                id="formControlInputCompany"
                placeholder="Facebook"
              />
            </div>
          </div>
          <div className="divRight">
            <h6 className="fw-bold text-center">Choose Service(s)</h6>
            <div className="gRChoose">
              <input
                type="checkbox"
                className="btn-check"
                id="btn-check"
                autoComplete="off"
              />
              <label className="btn btn-outline-info" htmlFor="btn-check">
                Creative & Video
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btn-check-1"
                autoComplete="off"
              />
              <label className="btn btn-outline-info" htmlFor="btn-check-1">
                Influencer Marketing
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btn-check-2"
                autoComplete="off"
              />
              <label className="btn btn-outline-info" htmlFor="btn-check-2">
                Marketing Strategy
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btn-check-3"
                autoComplete="off"
              />
              <label className="btn btn-outline-info" htmlFor="btn-check-3">
                Web & App Development
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btn-check-4"
                autoComplete="off"
              />
              <label className="btn btn-outline-info" htmlFor="btn-check-4">
                Organic Growth
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btn-check-5"
                autoComplete="off"
              />
              <label className="btn btn-outline-info" htmlFor="btn-check-5">
                Media Buying
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-warning d-block mx-auto mt-4"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default CosmicConsult;

const Container = styled.section`
  position: relative;
  background: #000;
  .block {
    padding: 5rem 1rem 2rem;
    @media screen and (min-width: 768px) {
      padding: 20rem 1rem 2rem;
    }
    @media screen and (min-width: 1024px) {
      padding: 20rem 2rem 2rem;
    }
    > form {
      margin: 0 auto;
      padding: 2rem 1rem;
      background: #161751;
      opacity: 0.9;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
      align-items: flex-start;
      @media screen and (min-width: 1024px) {
        flex-direction: row;
      }
      > .divLeft {
        width: 100%;
        display: grid;
        grid-template-columns: auto;
        gap: 1rem;
        @media screen and (min-width: 768px) {
          grid-template-columns: auto auto;
        }
        @media screen and (min-width: 1024px) {
          width: 50%;
          grid-template-columns: auto auto;
        }
        .colItem {
          > label {
            font-weight: bold;
            cursor: url(${CursorDefault}), auto !important;
          }
          > label,
          .form-control,
          .form-control::placeholder {
            border-radius: 1rem;
            color: #007dbc;
            font-feature-settings: "clig" off, "liga" off;
            font-family: "DM Sans";
          }
        }
      }
      > .divRight {
        width: 100%;
        font-family: "Turret Road";
        @media screen and (min-width: 1024px) {
          width: 50%;
        }
        > h6 {
          color: #fff;
        }
        > button {
          box-shadow: 0px 5px 9px 0px rgba(237, 172, 52, 0.3);
        }
        > .gRChoose {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 0.8rem;
          label,
          input[type="checkbox"] {
            cursor: url(${CursorPointer}), auto !important;
          }
        }
      }
    }
  }
`;
