import React, { useLayoutEffect } from "react";
// gsap
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";

const TermsOfServices = () => {
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    ScrollTrigger.refresh();
    return () => {
      ScrollTrigger.getAll().forEach((t) => {
        t.scroll(0);
        t.kill(true);
      });
    };
  }, []);
  return (
    <Container>
      <div className="title">
        <h2 className="fw-bold m-0">
          AIUR MARKETING
          <br />
          <small className="fw-light">TERMS OF SERVICE</small>
        </h2>
      </div>
      <div className="content">
        <ul>
          <li>
            Intro
            <div>
              Welcome to Aiur Marketing. Our services are designed to provide
              cutting-edge marketing solutions by integrating the wisdom of
              ancient civilizations with the latest in digital innovation,
              creating a unique platform where past, present, and future
              technologies converge to elevate your marketing strategies to new
              heights. By accessing or using our services, you agree to be bound
              by these Terms of Service ("Terms") and our Privacy Policy. These
              Terms govern your use of our website, services, products, and
              content (collectively, the "Services"). If you do not agree to
              these Terms, please do not use our Services.
            </div>
          </li>
          <li>
            Eligibility
            <div>
              Use of the Aiur Marketing Services is only available to
              individuals who are at least 18 years old or the age of majority
              in your jurisdiction, whichever is higher. By using our Services,
              you represent and warrant that you meet these eligibility
              requirements.
              <br />
              Account Registration and Use
              <ul>
                <li>
                  Creating an Account: To access certain features of our
                  Services, you must register for an account. You agree to
                  provide accurate, current, and complete information during the
                  registration process and to update such information to keep it
                  accurate, current, and complete.
                </li>
                <li>
                  Account Responsibilities: You are responsible for safeguarding
                  your account, ensuring that no unauthorized person has access
                  to your account, and notifying us promptly of any unauthorized
                  use. You accept responsibility for all activities that occur
                  under your account.
                </li>
              </ul>
            </div>
          </li>
          <li>
            Content on Aiur Marketing
            <div>
              <ul>
                <li>
                  User-Generated Content: Aiur Marketing allows you to post,
                  link, store, share, and otherwise make available certain
                  information, text, graphics, videos, or other material
                  ("Content"). You are responsible for the Content that you post
                  to the Service, including its legality, reliability, and
                  appropriateness. By posting Content to the Service, you grant
                  Aiur Marketing a non-exclusive, worldwide, royalty-free,
                  perpetual license to use, modify, publicly perform, publicly
                  display, reproduce, and distribute such Content on and through
                  the Service.
                </li>
                <li>
                  Content Restrictions: You agree not to post Content that:
                </li>
                <li>Is unlawful or promotes unlawful activities.</li>
                <li>
                  Is harmful, harassing, defamatory, or discriminatory based on
                  race, gender, religion, nationality, disability, sexual
                  orientation, or age.
                </li>
                <li>
                  Infringes on any patent, trademark, trade secret, copyright,
                  or other proprietary rights of any party.
                </li>
                <li>
                  Contains or links to viruses, malicious code, or any other
                  harmful components designed to interrupt, destroy, or limit
                  the functionality of any computer software, hardware, or
                  telecommunications equipment.
                </li>
              </ul>
              Intellectual Property Rights
              <ul>
                <li>
                  Ownership of Services and Content: The Service and all
                  included content (text, graphics, logos, icons, images, audio
                  clips, video clips, digital downloads, data compilations, and
                  software) are the property of Aiur Marketing, its content
                  providers, or its licensors and are protected by national and
                  international copyright and intellectual property laws. Aiur
                  Marketing's trademarks may not be used in connection with any
                  product or service without the prior written consent of Aiur
                  Marketing.
                </li>
              </ul>
            </div>
          </li>
          <li>
            Termination
            <div>
              <ul>
                <li>
                  Termination by You: You may terminate your account at any time
                  by contacting customer service. If you voluntarily terminate
                  your account, you may still be liable for any outstanding
                  payments.
                </li>
                <li>
                  Termination by Aiur Marketing: Aiur Marketing may terminate or
                  suspend your account and bar access to the Service
                  immediately, without prior notice or liability, under our sole
                  discretion, for any reason whatsoever and without limitation,
                  including but not limited to a breach of the Terms.
                </li>
              </ul>
            </div>
          </li>
          <li>
            Modifications to the Service and Prices
            <div>
              <ul>
                <li>
                  Service Changes: Aiur Marketing reserves the right at any time
                  to modify or discontinue, temporarily or permanently, the
                  Service (or any part of it) with or without notice. We shall
                  not be liable to you or to any third party for any
                  modification, price change, suspension, or discontinuance of
                  the Service.
                </li>
                <li>
                  Price Changes: Prices for our services are subject to change
                  without notice. We reserve the right at any time to modify or
                  discontinue the Service (or any part or content thereof)
                  without notice at any time.
                </li>
              </ul>
            </div>
          </li>
          <li>
            Links to Other Web Sites
            <div>
              Third-Party Links: Our Service may contain links to third-party
              web sites or services that are not owned or controlled by Aiur
              Marketing. Aiur Marketing has no control over, and assumes no
              responsibility for, the content, privacy policies, or practices of
              any third-party web sites or services. You further acknowledge and
              agree that Aiur Marketing shall not be responsible or liable,
              directly or indirectly, for any damage or loss caused or alleged
              to be caused by or in connection with the use of or reliance on
              any such content, goods, or services available on or through any
              such web sites or services.
              <br />
              Indemnification
              <br />
              You agree to defend, indemnify, and hold harmless Aiur Marketing
              and its licensee and licensors, and their employees, contractors,
              agents, officers, and directors, from and against any and all
              claims, damages, obligations, losses, liabilities, costs or debt,
              and expenses (including but not limited to attorney's fees),
              resulting from or arising out of
              <ul style={{ listStyle: "lower-alpha" }}>
                <li>
                  your use and access of the Service, by you or any person using
                  your account and password;
                </li>
                <li>a breach of these Terms, or</li>
                <li>Content posted on the Service.</li>
              </ul>
            </div>
          </li>
          <li>
            Limitation of Liability
            <div>
              In no event shall Aiur Marketing, nor its directors, employees,
              partners, agents, suppliers, or affiliates, be liable for any
              indirect, incidental, special, consequential, or punitive damages,
              including without limitation, loss of profits, data, use,
              goodwill, or other intangible losses, resulting from
              <ul style={{ listStyle: "lower-roman" }}>
                <li>
                  your access to or use of or inability to access or use the
                  Service;
                </li>
                <li>
                  any conduct or content of any third party on the Service;
                </li>
                <li>any content obtained from the Service; and </li>
                <li>
                  unauthorized access, use or alteration of your transmissions
                  or content, whether based on warranty, contract, tort
                  (including negligence), or any other legal theory, whether or
                  not we have been informed of the possibility of such damage,
                  and even if a remedy set forth herein is found to have failed
                  its essential purpose.
                </li>
              </ul>
              Dispute Resolution
              <ul>
                <li>
                  Governing Law: These Terms shall be governed and construed in
                  accordance with the laws of [Jurisdiction], without regard to
                  its conflict of law provisions.
                </li>
                <li>
                  Dispute Resolution: Our goal is to resolve disputes amicably
                  and efficiently. Should conflict arise, we strongly encourage
                  contacting us directly to seek a resolution before resorting
                  to legal action.
                </li>
              </ul>
              General Terms
              <ul>
                <li>
                  Entire Agreement: These Terms constitute the entire agreement
                  between us regarding our Service and supersede and replace any
                  prior agreements we might have had between us regarding the
                  Service.
                </li>
                <li>
                  No Waiver: No waiver of any term of these Terms shall be
                  deemed a further or continuing waiver of such term or any
                  other term, and Aiur Marketing's failure to assert any right
                  or provision under these Terms shall not constitute a waiver
                  of such right or provision.
                </li>
              </ul>
            </div>
          </li>
          <li>
            Other
            <div>
              <ul>
                <li>
                  Changes to Terms
                  <ul>
                    <li>
                      Aiur Marketing reserves the right, at our sole discretion,
                      to modify or replace these Terms at any time. If a
                      revision is material, we will provide at least 30 days'
                      notice prior to any new terms taking effect. What
                      constitutes a material change will be determined at our
                      sole discretion.
                    </li>
                    <li>
                      By continuing to access or use our Service after those
                      revisions become effective, you agree to be bound by the
                      revised terms. If you do not agree to the new terms, you
                      are no longer authorized to use the Service.
                    </li>
                  </ul>
                </li>
                <li>
                  Contact Information
                  <ul>
                    <li>
                      If you have any questions about these Terms, please
                      contact us through the designated channels on our website.
                      Our team is dedicated to assisting you and ensuring your
                      experience with Aiur Marketing is exceptional.
                    </li>
                  </ul>
                </li>
                <li>
                  Feedback and Complaints
                  <ul>
                    <li>
                      We welcome any feedback, comments, and concerns about our
                      Services. Please direct all feedback to our customer
                      service team. Your insights are valuable to us and help us
                      improve our Services.
                    </li>
                  </ul>
                </li>
                <li>
                  Accessibility
                  <ul>
                    <li>
                      Aiur Marketing is committed to making its Service
                      accessible to as many users as possible by continually
                      working to increase the accessibility and usability of our
                      website and in doing so adhere to many of the available
                      standards and guidelines.
                    </li>
                  </ul>
                </li>
                <li>
                  Language
                  <ul>
                    <li>
                      These Terms were written in English. To the extent any
                      translated version of these Terms conflicts with the
                      English version, the English version controls.
                    </li>
                  </ul>
                </li>
                <li>
                  Acknowledgment
                  <ul>
                    <li>
                      By using our Services, you acknowledge that you have read
                      these Terms of Service and agree to be bound by them.
                    </li>
                  </ul>
                </li>
                <li>
                  Severability
                  <ul>
                    <li>
                      If any provision of these Terms is held to be invalid or
                      unenforceable by a court, the remaining provisions of
                      these Terms will remain in effect. This clause ensures the
                      document as a whole continues to operate even if one part
                      is removed.
                    </li>
                  </ul>
                </li>
                <li>
                  Force Majeure
                  <ul>
                    <li>
                      Aiur Marketing shall not be liable for any failure to
                      perform its obligations hereunder where such failure
                      results from any cause beyond Aiur Marketing's reasonable
                      control, including, without limitation, mechanical,
                      electronic, or communications failure or degradation.
                    </li>
                  </ul>
                </li>
                <li>
                  Survival
                  <ul>
                    <li>
                      All provisions of the Terms which by their nature should
                      survive termination shall survive termination, including,
                      without limitation, ownership provisions, warranty
                      disclaimers, indemnity, and limitations of liability.
                    </li>
                  </ul>
                </li>
              </ul>
              This conclusion to the Terms of Service ensures that users are
              aware of the most critical aspects of their agreement with Aiur
              Marketing, emphasizing the importance of transparency,
              communication, and legal integrity in fostering a trustworthy
              relationship with the service provider.
            </div>
          </li>
        </ul>
      </div>
    </Container>
  );
};

export default TermsOfServices;

const Container = styled.section`
  background: #00253a;
  padding: 5rem 1rem 2rem;
  @media screen and (min-width: 1024px) {
    padding: 5rem 2rem 2rem;
  }
  .title {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 65px #0da8ff;
    font-family: "Turret Road";
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }
  .content {
    line-height: 1.6;
    color: #7ddbf9;
    > ul {
      list-style: upper-roman;
      color: #7ddbf9;
      > li {
        text-shadow: 0px 0px 60px rgba(255, 255, 255, 0.4);
        font-family: "Turret Road";
        font-size: 1.2rem;
        font-weight: bold;
        > div {
          font-family: Inter;
          font-size: 1rem;
          text-align: justify;
          font-weight: lighter;
          margin-bottom: 1rem;
          & > ul {
            list-style: disc;
          }
        }
        &:last-of-type {
          div > ul {
            list-style: decimal !important;
            li > ul {
              list-style: disc;
            }
          }
        }
      }
    }
  }
`;
