import React from "react";
import styled from "styled-components";

const CrewMobile = ({
  tit1,
  tit2,
  content,
  img,
  setCrewTit1,
  setCrewTit2,
  setCrewContent,
  setCrewImg,
}) => {
  return (
    <Container
      style={{
        background: `url(${img}) center center / 100% 100%`,
      }}
    >
      <div className="wrapContent">
        <div className="crewText">
          <h4>{tit1}</h4>
          <h6>({tit2})</h6>
          <button
            onClick={() => {
              setCrewTit1(tit1);
              setCrewTit2(tit2);
              setCrewImg(img);
              setCrewContent(content);
            }}
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasCrew"
            aria-controls="offcanvasCrew"
          >
            {">"} Read more {"<"}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default CrewMobile;
const Container = styled.div`
  height: 100%;
  width: 100%;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: 3px solid rgba(251, 188, 4, 0.7);
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(59, 212, 255, 0.2) 0%,
      rgba(59, 212, 255, 0.2) 100%
    );
  }
  .wrapContent {
    position: relative;
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
`;
