import React from "react";

const ModalOurStorySecond = () => {
  return (
    <div className="modalOurStory">
      <h5>COSMO'S NEIGHBORS</h5>
      <img
        alt=""
        src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/ourstory-adventurer2_wjesxb.png"
        className="w-100"
      />
      <p>
        In the cosmic expanse of Aiur Marketing, a group of unique individuals,
        known as the Cosmo's Neighbors, embarked on a journey unlike any other.
        Each held a key role, their destinies intertwined in the fabric of the
        digital universe.
        <br />
        <br />
        The Probe, once a solitary content creator, discovered a hidden realm of
        ideas, revealing secrets that would change the course of their mission.
        The Sentry, known for optimizing the unseen, encountered an algorithmic
        anomaly, leading to unforeseen digital landscapes.
        <br />
        <br />
        Amidst the stars, the Mothership, their wise and powerful leader, faced
        a cosmic upheaval, challenging her reign and forcing her to navigate
        through turbulent nebulae of market shifts.
        <br />
        <br />
        The Aiur Lieutenant, a valiant marketing leader, uncovered a conspiracy
        within the advertising galaxies, testing his loyalty and strategy. The
        Phoenix, a sales maverick, rose from the ashes of a collapsed campaign,
        finding new ways to elevate their message.
        <br />
        <br />
        In the shadowy corners of the cosmos, the Observer, the planner and
        seer, grappled with a vision of a divergent digital future, steering the
        crew towards uncharted territories. The Zealot, the meticulous editor,
        faced a personal challenge when an interstellar message altered the
        course of their narrative, leading to a crossroads of creativity and
        precision.
        <br />
        <br />
        And the Carrier, the backbone of the crew, rallied the team during a
        resource crisis, unveiling hidden strengths and forging unbreakable
        bonds among the Cosmo's Neighbors.
        <br />
        Together, they navigated through these twists and turns, their journey a
        testament to resilience, innovation, and the unyielding spirit of
        adventure in the digital cosmos.
      </p>
    </div>
  );
};

export default ModalOurStorySecond;
