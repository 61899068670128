import React from "react";

const ModalOurStoryFirst = () => {
  return (
    <div className="modalOurStory">
      <h5>ADVENTURERS OF THE DIGITAL REALM</h5>
      <img
        alt=""
        src="https://res.cloudinary.com/db85dpvaz/image/upload/aiur-marketing/ourstory-adventurer1_siw28e.jpg"
        className="w-100"
      />
      <p>
        In the realm of digital marketing, a vast universe unfolds, teeming with
        opportunities and challenges. It's a cosmos where innovation and
        strategy collide, forming the nebula of success. In this ever-expanding
        digital space, our Adventurers embark on quests of discovery and
        triumph.
        <br />
        <br />
        As Humanity once ventured into the unknown reaches of space, our
        Adventurers explore the uncharted territories of the digital world. Each
        campaign is a new planet to conquer, each strategy a star to navigate
        by. In this universe, the Adventurers are not mere spectators but active
        explorers, shaping their destinies with every click, every post, every
        campaign.
        <br />
        <br />
        With Aiur Marketing as their guide, they traverse this digital cosmos,
        encountering diverse civilizations of consumers and crafting messages
        that resonate across the galaxy. From the turbulent social media nebulae
        to the serene expanse of SEO optimization, each Adventurer's journey is
        unique.
        <br />
        <br />
        Yet, the digital universe is not without its challenges. Like the
        interstellar wars of old, our Adventurers face fierce competition and
        ever-changing algorithms. But with Aiur's expertise, they rise above,
        harnessing the power of digital tools to forge their path to success.
        <br />
        <br />
        Today, as the digital era evolves, Aiur Marketing and its band of
        Adventurers stand ready to face new challenges, adapt to emerging
        trends, and seize the opportunities that lie in the vast, virtual
        cosmos. Together, we chart a course towards a future where every
        Adventurer's brand shines bright, a beacon of success in the digital
        galaxy.
      </p>
    </div>
  );
};

export default ModalOurStoryFirst;
